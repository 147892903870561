// Helpers to add event dispatch to classes
// Usage: class Foo extends Evented(['connect', 'disconnect', 'message'])

export class EventBus {
  constructor(eventTypes = []) {
    this.listeners = {};
    eventTypes.forEach(type => (this.listeners[type] = new Set()));
  }

  emit(type, data) {
    if (type in this.listeners) {
      this.listeners[type].forEach(handler => handler(data));
    } else {
      console.warn(`EventBus attempted to emit unregistered event type ${type}`);
    }
  }

  on(type, handler) {
    if (type in this.listeners) {
      this.listeners[type].add(handler);
    }
  }

  off(type, handler) {
    if (type in this.listeners) {
      this.listeners[type].delete(handler);
    }
  }
}

export function Evented(eventTypes) {
  return class {
    constructor() {
      this.listeners = {};
      eventTypes.forEach(type => (this.listeners[type] = new Set()));
    }

    emit(type, data) {
      if (type in this.listeners) {
        this.listeners[type].forEach(handler => handler(data));
      }
    }

    on(type, handler) {
      if (type in this.listeners) {
        this.listeners[type].add(handler);
      }
    }

    off(type, handler) {
      if (type in this.listeners) {
        this.listeners[type].delete(handler);
      }
    }
  };
}

export function StaticEvented(eventTypes) {
  const listeners = {};
  eventTypes.forEach(type => (listeners[type] = new Set()));

  const scopes = new Map();

  return class {
    static emit(type, data) {
      if (type in listeners) {
        listeners[type].forEach(handler => handler(data));
      }
    }

    static on(type, handler, scope) {
      if (type in listeners) {
        listeners[type].add(handler);

        if (scope) {
          if (!scopes.has(scope)) {
            scopes.set(scope, []);
          }
          scopes.get(scope).push([type, handler]);
        }
      }
    }

    static off(type, handler) {
      if (type in listeners) {
        listeners[type].delete(handler);
      }
    }

    static scopeOff(scope) {
      if (scopes.has(scope)) {
        scopes.get(scope).forEach(([type, handler]) => {
          this.off(type, handler);
        });

        scopes.delete(scope);
      }
    }
  };
}
