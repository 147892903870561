import { html, Component } from 'htm/preact';

import { NORTH, EAST, SOUTH, WEST } from './constants';

export const getTileAsset = (assets, tile) => {
  if (!tile) {
    return null;
  }

  const match = tile.match(/^(?<sides>[pgr]{4})(?<center>[ptqrg])(?<star>[s])?(?<seq>\d+)?$/);
  if (!match) {
    console.error('could not match tile', tile);
  }
  const sides = match.groups.sides;
  const center = match.groups.center;
  const star = match.groups.star === 's';
  const seq = (match.groups.seq && Math.trunc(match.groups.seq)) || 0;

  const name = sides + center + (star ? 's' : '');

  const asset = assets.tiles[name];
  if (asset instanceof Array) {
    return asset[seq % asset.length];
  }
  return asset;
};

export const meeplePosition = position => {
  switch (position) {
    case NORTH:
      return [0, -1];
    case WEST:
      return [-1, 0];
    case SOUTH:
      return [0, 1];
    case EAST:
      return [1, 0];
  }
  return [0, 0];
};

export class Tile extends Component {
  render({ tile, orientation = 0, assets = {} }) {
    let tileAsset = getTileAsset(assets, tile);

    return html`
      <div class="tile" data-name="${tile}">
        ${tileAsset &&
        html`
          <img
            class="tile-graphic"
            src="${tileAsset.url}"
            style="transform:rotate(${orientation * 90}deg)"
          />
        `}
      </div>
    `;
  }
}
