import { html, Component } from 'htm/preact';

export default class Toolbar extends Component {
  constructor() {
    super();
  }

  render({ pageName, icons, players }) {
    return html`
      <div class="exq__header">🔡 Exquisite Corp. Writer - ${pageName}</div>
      <div class="exq__toolbar">
        ${icons.map(
          icon =>
            html`
              <b>${icon}</b>
            `
        )}
        ${players &&
        html`
          <div class="exq__toolbar__players">
            ${Object.values(players).map(
              player => html`
                <div class="exq__toolbar__player" style="background: ${player.color}">
                  ${String.fromCodePoint(player.name.codePointAt(0))}
                </div>
              `
            )}
          </div>
        `}
      </div>
    `;
  }
}
