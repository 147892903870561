import { html, Component } from 'htm/preact';

import { setAccount } from '../actions';

import Socket from '../interfaces/socket';

import { htmlValidateForm } from '../utils/validationErrors';

class LoginSignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.mode || 'login',
      validationErrors: {},
    };
  }

  async submitForm(form) {
    const { valid, validationErrors } = htmlValidateForm(form);

    this.setState({ validationErrors });

    if (!valid) {
      console.log('not submitting form due to form html validation errors');
      return false;
    }

    const mode = this.state.mode;
    const formData = new FormData(form);
    const type = form.getAttribute('data-action');
    const data = {};

    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1];
    }

    Socket.rpc({ type, data })
      .then(({ type: responseType, data: responseData }) => {
        this.onSuccess(mode, responseData);
      })
      .catch(error => {
        if (this.state.mode !== mode) {
          // User switched modes before server response, ignore validation errors from original action
          return;
        }

        if (error.type === 'validation-error') {
          this.setState({ validationErrors: error.data });
        }

        /* TODO other unhandled types */
      });
  }

  onSuccess(mode, data) {
    if (mode === 'login') {
      setAccount(data);
      this.props.onLoginSuccess();
    } else if (mode === 'signup') {
      setAccount(data);
      this.props.onSignupSuccess();
    } else if (mode === 'reset-password') {
      this.props.onResetPasswordSuccess();
    }
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.submitForm(e.target);

    return false;
  }

  onChange(e) {
    const fieldName = e.target.name;

    let validationErrors = Object.assign({}, this.state.validationErrors);
    delete validationErrors[fieldName];

    this.setState({ validationErrors });
  }

  switchMode(newMode) {
    this.setState({ validationErrors: {}, mode: newMode });
  }

  render({ hideTitle = false, multiMode = true }, { mode, validationErrors }) {
    const onChange = this.onChange.bind(this);

    const usernameErrors = validationErrors.username || [];
    const passwordErrors = validationErrors.password || [];
    const emailErrors = validationErrors.email || [];

    const usernameInvalid = usernameErrors.length > 0;
    const passwordInvalid = passwordErrors.length > 0;
    const emailInvalid = emailErrors.length > 0;

    let contents, confirmText;

    const usernameElement = html`
      <input
        class="gr-input ${usernameInvalid ? 'invalid' : ''}"
        key="username"
        name="username"
        autocorrect="off"
        autocomplete="username"
        autocapitalize="off"
        spellcheck="false"
        placeholder="Username"
        required
        onChange=${onChange}
      />
      ${usernameErrors.map(
        error => html`
          <div class="input-error-message">${error}</div>
        `
      )}
    `;

    const passwordElement = html`
      <input
        class="gr-input ${passwordInvalid ? 'invalid' : ''}"
        key="password"
        name="password"
        type="password"
        placeholder="Password"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        autocomplete="current-password"
        required
        onChange=${onChange}
      />
      ${passwordErrors.map(
        error => html`
          <div class="input-error-message">${error}</div>
        `
      )}
    `;

    const emailElement = html`
      <input
        class="gr-input ${emailInvalid ? 'invalid' : ''}"
        key="email"
        name="email"
        type="email"
        autocorrect="off"
        autocomplete="email"
        autocapitalize="off"
        spellcheck="false"
        placeholder="Email (Optional)"
        onChange=${onChange}
      />
      ${emailErrors.map(
        error => html`
          <div class="input-error-message">${error}</div>
        `
      )}
    `;

    const submitButton = confirmText => html`
      <button type="submit" class="gr-button">${confirmText}</button>
    `;

    if (mode === 'login') {
      return html`
        <div class="login-signup-form">
          ${!hideTitle &&
          html`
            <h3>Login</h3>
          `}
          <form
            method="post"
            action="/accounts/login"
            novalidate
            onSubmit=${this.onSubmit.bind(this)}
            data-action="account:login"
          >
            ${usernameElement} ${passwordElement} ${submitButton('Login')}
          </form>
          ${multiMode &&
          html`
            <div class="login-signup-form__switch-modes">
              New here?
              <a onClick=${() => this.switchMode('signup')}>Sign Up</a>
              <!-- a onClick=${() => this.switchMode('reset-password')}>Reset Password</a -->
            </div>
          `}
        </div>
      `;
    } else if (mode === 'signup') {
      return html`
        <div class="login-signup-form">
          ${!hideTitle &&
          html`
            <h3>Sign Up</h3>
          `}
          <form
            method="post"
            action="/accounts/create"
            novalidate
            onSubmit=${this.onSubmit.bind(this)}
            data-action="account:create"
          >
            ${usernameElement} ${emailElement} ${passwordElement} ${submitButton('Sign Up')}
          </form>
          ${multiMode &&
          html`
            <div class="login-signup-form__switch-modes">
              <a onClick=${() => this.switchMode('login')}>Login</a>
            </div>
          `}
        </div>
      `;
    } /* (mode === 'reset-password') */ else {
      return html`
        <div class="login-signup-form">
          ${!hideTitle &&
          html`
            <h3>Reset Password</h3>
          `}
          <form
            method="post"
            action="/accounts/reset-password"
            novalidate
            onSubmit=${this.onSubmit.bind(this)}
            data-action="account:reset-password"
          >
            ${usernameElement} ${emailElement} ${submitButton('Reset Password')}
          </form>
          ${multiMode &&
          html`
            <div class="login-signup-form__switch-modes">
              <a onClick=${() => this.switchMode('login')}>Login</a>
              <a onClick=${() => this.switchMode('signup')}>Sign Up</a>
            </div>
          `}
        </div>
      `;
    }
  }
}

export default LoginSignupForm;
