import { html, Component } from 'htm/preact';

import { addToast } from '../actions';

import Header from './Header';

const MAX_PLAYER_NAME_LENGTH = 20;

class Lobby extends Component {
  changeUserName(name) {
    if (name.length > MAX_PLAYER_NAME_LENGTH) {
      addToast({
        type: 'error',
        message: 'User name cannot be longer than 20 characters',
      });
      this.setState({ userNameError: true });
      return;
    }
    if (this.state.userNameError) {
      this.setState({ userNameError: false });
    }

    this.props.changeUserSettings({ name });
    localStorage.setItem('lastUserName', name);
  }

  submitUserSettingsForm(e) {
    e.preventDefault();
    this.changeUserName(e.target.elements.userName.value);
  }

  blurUserSettingsInput(e) {
    if (e.target.name === 'userName') {
      this.changeUserName(e.target.value);
    }
  }

  startGame(e) {
    e.preventDefault();

    if (!this.myUserName()) {
      addToast({
        type: 'error',
        message: 'Cannot start game without a user name',
      });
      return;
    }

    this.props.startGame();
  }

  componentWillReceiveProps(nextProps) {
    let userNameJustChanged = this.myUserName() !== this.myUserName(nextProps);
    this.setState({
      userNameJustChanged,
    });
  }
  componentDidUpdate() {
    if (this.state.userNameJustChanged && !this.userNameChangeTimeout) {
      this.userNameChangeTimeout = setTimeout(() => {
        this.setState({ userNameJustChanged: false });
        this.userNameChangeTimeout = null;
      }, 1000);
    }
  }

  myUserName(props = this.props) {
    let room = props.room;
    let myUser = props.user;

    let users = room ? room.users || [] : [];
    let myRoomUser = users.find(({ id }) => id === myUser.id);
    if (myRoomUser) {
      return myRoomUser.name;
    } else {
      return 'Unnamed user';
    }
  }

  render(
    {
      user,
      room,
      canStartGame = false,
      gameSettingsComponent,
      gameSettings = {},
      changeGameSettings,
      assetLoadingProgress,
    },
    { userNameError, userNameJustChanged }
  ) {
    let users = room ? room.users || [] : [];
    let myUser = user;
    let myName = this.myUserName();
    let vip = room ? users.find(user => user.id === room.vip) : null;
    let amIVip = vip && myUser && vip.id === myUser.id;
    let otherPlayers = users.filter(({ id }) => id !== user.id);
    let roomCode = room ? room.code : '...';
    let loadingProgress = 0;
    let loadingProgressPretty = 0;
    if (assetLoadingProgress) {
      loadingProgress = assetLoadingProgress.done / assetLoadingProgress.total;
      loadingProgressPretty = Math.floor(loadingProgress * 100);
    }

    let loadingText = 'Loading…';
    if (assetLoadingProgress) {
      if (loadingProgress < 1) {
        loadingText = `Loading game assets… ${loadingProgressPretty}%`;
      } else {
        // loaded locally
        if (amIVip) {
          if (canStartGame) {
            loadingText = 'Ready to start!';
          } else {
            loadingText = 'Waiting on players…';
          }
        } else {
          // I am not VIP
          let vipName = 'VIP';
          if (vip && vip.name) {
            vipName = vip.name;
          }
          loadingText = html`
            <span>Waiting for</span>
            <strong>${' ' + vipName + ' '}</strong>
            <span>to start game…</span>
          `;
        }
      }
    }

    return html`
      <div class="lobby__container">
        <${Header}>
          Welcome to <span class="room-code">${roomCode}</span>
        </Header>

        <section class="you">
          <h2>Enter your name</h2>
          <form class="inline-button" onSubmit=${e => this.submitUserSettingsForm(e)}>
            <span class="confirm-save ${userNameJustChanged ? 'visible' : ''}">✓</span>
            <svg class="confirm-error ${userNameError ? 'visible' : ''}"
                 style="height:1em" viewBox="0 0 16 16">
              <path d="M 2,0 L 16,14 14,16 0,2z"/>
              <path d="M 14,0 L 0,14 2,16 16,2z"/>
            </svg>
            <input class="gr-input ${userNameError ? 'invalid' : ''}"
                   name="userName"
                   autocorrect="off"
                   autocapitalize="off"
                   spellcheck="false"
                   defaultValue=${myName}
                   maxLength=${MAX_PLAYER_NAME_LENGTH}
                   minLength="1"
                   placeholder="Unnamed user"
                   onBlur=${e => this.blurUserSettingsInput(e)}
            />

            <button class="gr-button" type="submit">save</button>
          </form>
        </section>

        <section class="other-players">
          <h2>Other Players</h2>
          <ul>
            ${
              otherPlayers.length === 0 &&
              html`
                <li class="user-list-icon waiting-for-other-players">...waiting...</li>
              `
            }
            ${otherPlayers.map(
              ({ id, name, connectionState }) => html`
                <li class="user-list-icon">
                  ${connectionState !== 'connected'
                    ? html`
                        <span class="user-disconnected">👻</span>
                      `
                    : ''}
                  ${name || 'Unnamed user'}
                </li>
              `
            )}
          </ul>
        </section>

        ${
          amIVip &&
          gameSettings &&
          gameSettingsComponent &&
          html`
            <section class="settings">
              <details>
                <summary><h2>Settings</h2></summary>
                <form onSubmit=${e => e.preventDefault()}>
                  <${gameSettingsComponent}
                    settings=${gameSettings}
                    changeGameSettings=${changeGameSettings}
                  />
                </form>
              </details>
            </section>
          `
        }

        <section class="loading">
          ${loadingText}
        </section>

        ${
          amIVip &&
          html`
            <form class="start-game" onSubmit=${e => this.startGame(e)}>
              <button disabled=${!canStartGame} class="gr-button" type="submit">start game</button>
            </form>
          `
        }
      </div>
    `;
  }
}

export default Lobby;
