export async function animate(el, name, ms) {
  el.style.animation = `${ms}ms ${name} ease-out`;
  await wait(ms * 1.2);
  el.style.animation = 'none';
}

export const wait = ms => new Promise(r => setTimeout(r, ms));

export const lerp = (a, b, i) => a + (b - a) * i;
export const clamp = (a, b, i) => (i < a ? a : i > b ? b : i);
export const easeOut = i => Math.sqrt(i);
export const ease = i => (1 - Math.cos(i * Math.PI)) / 2;

export const formatTime = ms => {
  let seconds = Math.floor(ms / 1000);
  return Math.floor(seconds / 60) + ':' + (seconds % 60).toString().padStart(2, '0');
};

// camelize('foo_bar') => 'fooBar'
export const camelize = str =>
  str.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2, offset) =>
    p2 ? p2.toUpperCase() : p1.toLowerCase()
  );

// decamelize('fooBar') => 'foo_bar'
export const decamelize = str =>
  str
    .replace(/([a-z\d])([A-Z])/g, '$1_$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1_$2')
    .toLowerCase();
