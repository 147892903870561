import { html } from 'htm/preact';

import Game from '../game';

import Card from './Card';
import Die from './Die';
import PositionTracker from './PositionTracker';

import './half_truth.css';

const PLAYER_STATES = {
  WAITING: 'waiting',
  AWAITING_CARD_DATA: 'awaiting-card-data',
  AWAITING_GUESSES: 'awaiting-guesses',
  GUESSES_LOCKED: 'guesses-locked',
  AWAITING_CARD_ANSWERS: 'awaiting-card-answers',
  WINNER: 'winner',
  LOSER: 'loser',
};

class HalfTruth extends Game {
  constructor({ user, room, gameState, sendGameAction }) {
    super();
    this.state = {
      zoom: 7,
      userName: this.getUserName(user.id, room),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.gameState !== nextProps.gameState) {
      // console.log('changing props, new gameState:', nextProps.gameState);
    }
  }

  getUserName(userId, room) {
    for (let user of room.users) {
      if (user.id === userId) {
        return user.name;
      }
    }
    return undefined;
  }

  myPlayerState() {
    return this.myPlayer().state;
  }

  myPlayer() {
    const {
      gameState: { players = [] },
    } = this.props;
    return players.find(player => player.name === this.state.userName);
  }

  gameOver() {
    const {
      gameState: { players = [] },
    } = this.props;
    return players.some(player => player.state === 'winner');
  }

  allowGuesses() {
    return this.myPlayerState() === PLAYER_STATES.AWAITING_GUESSES;
  }

  allowEnteringAnswers() {
    return this.myPlayerState() === PLAYER_STATES.AWAITING_CARD_ANSWERS;
  }

  // type: 'game:enter-card-question'

  // type: 'game:lock-guesses'
  lockGuesses(guesses) {
    if (!this.allowGuesses()) {
      // this.props.addToast({ type: 'error', message: 'You cannot guess now' })
      return;
    }

    this.props.sendGameAction({ type: 'game:lock-guesses', data: { guesses } });
  }

  // type: 'game:enter-card-answers'
  enterCardAnswers(answers) {
    if (!this.allowEnteringAnswers()) {
      // this.props.addToast({ type: 'error', message: 'You cannot enter answers now' })
      return;
    }

    this.props.sendGameAction({
      type: 'game:enter-card-answers',
      data: { answers },
    });
  }

  render({ gameState: { die, positionTracker, players } }) {
    let cardMode = 'display';
    if (this.allowGuesses()) cardMode = 'guess';
    if (this.allowEnteringAnswers()) cardMode = 'enter-answers';

    let myGuesses = this.myPlayer().currentGuesses || [];

    return html`
      ${!this.gameOver() &&
      html`
        <${Die} die=${die} />
      `}

      <${PositionTracker} positionTracker=${positionTracker} players=${players} />

      <${Card}
        mode=${cardMode}
        myGuesses=${myGuesses}
        lockGuesses=${this.lockGuesses.bind(this)}
        enterCardAnswers=${this.enterCardAnswers.bind(this)}
      />
    `;
  }
}

export default HalfTruth;
