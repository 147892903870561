import { html } from 'htm/preact';

const FLAG_CLOTH_PATH =
  'M32,46 C68,12 106.904927,8.46207862 139,11 C170.94333,13.5259223 185,39 223.246786,39.094917 C261.493572,39.189834 287.245643,11.1008772 313.594854,10.5409757 C331.160995,10.167708 345.387033,15.9140048 352.272968,27.7798661 L390.802228,100.309183 C378.104037,96.1514893 361.552238,97.4461264 343.894571,104.327186 C316.794484,114.887885 301.312374,174.097811 259.737186,185.862057 C218.161997,197.626302 190.06531,184.359432 169.032655,188.179716 C148,192 137.531681,197.216154 116,215 C106.320009,222.995078 85.3906467,238.552213 77.457235,241.341965 L32,46 Z';
const FLAG_POLE_PATH =
  'M10.2408348,23.2891841 L100.240835,446.289184 C101.505116,452.231307 107.347061,456.023447 113.289184,454.759165 C119.231307,453.494884 123.023447,447.652939 121.759165,441.710816 L31.7591652,18.7108159 C30.4948838,12.768693 24.6529388,8.97655332 18.7108159,10.2408348 C12.768693,11.5051162 8.97655332,17.3470612 10.2408348,23.2891841 Z';

export const flag = (color, size = 16) => html`
  <svg viewBox="-8 -8 407 471" width="${size}" height="${size}" class="flag">
    <g id="Page-1" stroke="none" stroke-width="15" fill="none" fill-rule="evenodd">
      <path d="${FLAG_CLOTH_PATH}" fill="${color}" stroke="#000"></path>
      <path d="${FLAG_POLE_PATH}" fill="#682B00" fill-rule="nonzero" stroke="#682B00"></path>
      <circle id="Oval" fill="#FFBF00" cx="21" cy="21" r="24" stroke="#000"></circle>
      <circle id="Oval" fill="#FFFFFF" cx="14.6230469" cy="13.6230469" r="10"></circle>
    </g>
  </svg>
`;

export const FLAG_CLOTH = new Path2D(FLAG_CLOTH_PATH);
export const FLAG_POLE = new Path2D(FLAG_POLE_PATH);
