import { html, Component } from 'htm/preact';
import { createRef } from 'preact';
import { animate, formatTime } from '../../utils';
import { meeple } from './meeple';

const SCORE_TIMEOUT = 2000;
const MAX_MEEPLES = 7;

export default class Stats extends Component {
  constructor() {
    super();
    this.scoreUpdates = [];
    this.state = {
      localTimeElapsed: 0,
    };
    this.currentPlayerRow = createRef();
    this.activePlayerIndicator = createRef();

    setInterval(() => {
      if (this.props.useChessTimer && this.props.localStartTimeOfCurrentTurn) {
        this.setState({
          localTimeElapsed: Date.now() - this.props.localStartTimeOfCurrentTurn,
        });
      }
    }, 200);
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.scoreUpdates && Object.keys(nextProps.scoreUpdates).length > 0) {
      this.setState({ recentPlayerScoreUpdates: nextProps.scoreUpdates });
    }
  }

  componentDidUpdate() {
    if (this.scoreUpdates.length) {
      Promise.all(
        this.scoreUpdates.map(scoreUpdateRef =>
          animate(scoreUpdateRef.current, 'score', SCORE_TIMEOUT)
        )
      ).then(() => {
        this.hideRecentPlayerScoreUpdates();
      });
    }
    this.moveActivePlayerIndicator();
  }

  componentDidMount() {
    this.moveActivePlayerIndicator();
  }

  moveActivePlayerIndicator() {
    if (!this.currentPlayerRow || !this.currentPlayerRow.current) {
      return;
    }
    if (!this.activePlayerIndicator || !this.activePlayerIndicator.current) {
      return;
    }

    if (this.props.playerOrder.length > 1 && this.currentPlayerRow.current) {
      let playerEl = this.currentPlayerRow.current;
      let playerRowTop = playerEl.offsetTop + playerEl.offsetHeight / 2;
      this.activePlayerIndicator.current.style.display = 'block';
      this.activePlayerIndicator.current.style.transform = `translateY(calc(${playerRowTop}px - 50%))`;
    } else {
      this.activePlayerIndicator.current.style.display = 'none';
    }
  }

  hideRecentPlayerScoreUpdates() {
    this.setState({ recentPlayerScoreUpdates: {} });
  }

  render(
    { playerOrder, players, useChessTimer },
    { recentPlayerScoreUpdates = {}, localTimeElapsed }
  ) {
    this.scoreUpdates = [];

    let wideEnoughForMeepleBar = window.matchMedia('(min-width: 600px)').matches;
    let currentPlayerIndex;

    return html`
      <div id="stats" class="${useChessTimer ? 'chess-timer' : ''}">
        <span class="header" style="grid-row: 1; grid-column: 1;">Player</span>
        <span class="header" style="grid-row: 1; grid-column: 2;">Score</span>
        <span class="header" style="grid-row: 1; grid-column: 3;">Meeples</span>
        ${useChessTimer &&
        html`
          <span class="header" style="grid-row: 1; grid-column: 4;">Time</span>
        `}
        ${playerOrder.map((id, index) => {
          let player = players[id];
          let recentPlayerScoreUpdate = recentPlayerScoreUpdates[id] || 0;

          let scoreUpdateRef;
          if (recentPlayerScoreUpdate > 0) {
            scoreUpdateRef = createRef();
            this.scoreUpdates.push(scoreUpdateRef);
          }

          let isTurn = player.state === 'place-meeple' || player.state === 'place-tile';

          if (isTurn) {
            currentPlayerIndex = index;
          }

          let meepleBar = [];
          for (let i = 0; i < MAX_MEEPLES; i++) {
            if (i < player.meeples) {
              meepleBar.push(meeple(player.color));
            } else {
              meepleBar.push(meeple('#ddd'));
            }
          }

          let timeLeft = player.timeLeftOnClock;
          if (isTurn) {
            timeLeft -= localTimeElapsed;
          }
          timeLeft = Math.max(timeLeft, 0);

          let currentPlayerClasses = '';
          if (isTurn) {
            currentPlayerClasses += ' current-player';
          }
          if (player.state === 'forfeit') {
            currentPlayerClasses += ' forfeit';
          }

          return html`
            <span
              class="player-row ${currentPlayerClasses}"
              ref=${isTurn && this.currentPlayerRow}
              style="color:${player.color}; grid-row: ${index + 2}; grid-column: 1;"
            >
              <div
                class="player-marker ${isTurn && 'current-player'}"
                style="background-color:${player.color};"
              ></div>
              ${player.name}
            </span>

            <span
              class="player-row player-score ${currentPlayerClasses}"
              style="grid-row: ${index + 2}; grid-column: 2;"
            >
              ${recentPlayerScoreUpdate > 0 &&
              html`
                <span class="score-update" ref=${scoreUpdateRef}>+${recentPlayerScoreUpdate}</span>
              `}
              ${player.score}
            </span>
            <span
              class="player-row ${currentPlayerClasses}"
              style="grid-row: ${index + 2}; grid-column: 3;"
            >
              ${wideEnoughForMeepleBar
                ? meepleBar
                : [
                    meeple(player.color),
                    html`
                      × ${player.meeples}
                    `,
                  ]}
            </span>
            ${useChessTimer &&
            html`
              <span
                class="player-row ${currentPlayerClasses}"
                style="grid-row: ${index + 2}; grid-column: 4;"
              >
                ${formatTime(timeLeft)}
              </span>
            `}
          `;
        })}
        ${playerOrder.length > 1 &&
        html`
          <div class="stats-current-player" ref=${this.activePlayerIndicator} />
        `}
      </div>
    `;
  }
}
