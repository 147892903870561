import { StaticEvented } from '../utils/events';

export default class Audio extends StaticEvented(['mutedChange', 'volumeChange']) {
  static init() {
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) return;
    audioContext = new AudioContext();
    output = audioContext.createGain();
    output.connect(audioContext.destination);
    output.gain.setValueAtTime(volume, audioContext.currentTime);
    audioContextReady();
  }

  static getVolume() {
    let storedVolume = localStorage.getItem('volume');
    if (!storedVolume) return 0.5;

    let volume = JSON.parse(storedVolume);
    return Math.max(0, Math.min(volume, 1));
  }

  static getMuted() {
    let muted = localStorage.getItem('muted');
    if (!muted) return false;
    return !!JSON.parse(muted);
  }

  static setVolume(newVolume) {
    volume = Math.max(0, Math.min(newVolume, 1));
    if (output) {
      output.gain.setValueAtTime(volume, audioContext.currentTime);
    }
    localStorage.setItem('volume', JSON.stringify(volume));
    Audio.emit('volumeChange', volume);
  }

  static setMuted(newMuted = true) {
    muted = !!newMuted;
    localStorage.setItem('muted', JSON.stringify(muted));
    Audio.emit('mutedChange', muted);
  }

  static async loadSounds(sounds = {}) {
    await audioReady;
    const bufferDecodePromises = Object.entries(sounds).map(([soundName, soundSource]) => {
      if (buffers.has(soundName)) {
        return;
      }

      return new Promise(async resolve => {
        let soundData = await soundSource.arrayBuffer();
        audioContext.decodeAudioData(
          soundData,
          data => {
            buffers.set(soundName, data);
            resolve();
          },
          resolve
        );
      });
    });

    await Promise.all(bufferDecodePromises);
  }

  static playSound(name) {
    if (buffers.has(name) && !muted) {
      let source = audioContext.createBufferSource();
      source.buffer = buffers.get(name);
      source.connect(output);
      source.start(0);
    }
  }
}

let audioContext;
let soundReady = new Set();

let volume = Audio.getVolume();
let muted = Audio.getMuted();
let output;
let buffers = new Map();

let audioContextReady;
const audioReady = new Promise(resolve => {
  audioContextReady = resolve;
});
