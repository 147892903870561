import GlobalEventBus from '../globalEventBus';

export const setZoom = zoom => {
  GlobalEventBus.emit('setZoom', zoom);
};

export const zoomIn = () => {
  GlobalEventBus.emit('zoomIn');
};

export const zoomOut = () => {
  GlobalEventBus.emit('zoomOut');
};
