import { html, Component } from 'htm/preact';
import { createRef, createContext } from 'preact';
import { setZoom } from '../actions/ui';
import GlobalEventBus from '../globalEventBus';
import { lerp, ease, clamp } from '../utils';
import { Evented, EventBus } from '../utils/events';

const MIN_ZOOM = 1;
const DEFAULT_ZOOM = 5;
const MAX_ZOOM = 8;

const zoomToZoomFactor = zoom => Math.pow(2, (zoom - 4) / 2);
const zoomFactorToZoom = zoomFactor => 2 * Math.log2(zoomFactor) + 4;

export class Point {
  static fromMouseEvent(e) {
    return new Point(e.clientX, e.clientY);
  }

  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  add(p) {
    return new Point(this.x + p.x, this.y + p.y);
  }

  subtract(p) {
    return new Point(this.x - p.x, this.y - p.y);
  }

  lerp(p, t) {
    return new Point(lerp(this.x, p.x, t), lerp(this.y, p.y, t));
  }

  scale(k) {
    return new Point(this.x * k, this.y * k);
  }

  length() {
    return Math.hypot(this.x, this.y);
  }

  equals(p) {
    return this.x === p.x && this.y === p.y;
  }

  distanceTo(p) {
    return Math.hypot(this.x - p.x, this.y - p.y);
  }
}

export class Camera extends Evented(['change', 'changeDpr', 'changeWindowSize']) {
  constructor({ zoom = DEFAULT_ZOOM, minZoom = MIN_ZOOM, maxZoom = MAX_ZOOM }) {
    super();

    this.minZoom = minZoom;
    this.maxZoom = maxZoom;
    this.zoom = clamp(this.minZoom, this.maxZoom, zoom);

    this.updateTo({ point: new Point(0, 0), zoom });

    this.width = 0;
    this.height = 0;
    this.reevaluateDpr();

    this.currentAnimation = null;

    GlobalEventBus.on('setZoom', z => this.maybeSetZoom(z, true));
    GlobalEventBus.on('zoomIn', () => this.maybeSetZoom(this.zoom + 1));
    GlobalEventBus.on('zoomOut', () => this.maybeSetZoom(this.zoom - 1));
  }

  maybeSetZoom(zoom, continuous = false) {
    zoom = clamp(this.minZoom, this.maxZoom, zoom);

    if (zoom !== this.zoom) {
      if (continuous) {
        this.updateTo({ zoom });
      } else {
        this.animateTo({ zoom }, 200);
      }
    }
  }

  reevaluateDpr() {
    const newDpr = window.devicePixelRatio;

    if (this.dpr && this.dpr !== newDpr) {
      this.emit('changeDpr');
    }

    this.dpr = newDpr;
    return newDpr;
  }

  reevaluateSize(ref) {
    this.reevaluateDpr();

    if (ref && ref.current) {
      const newHeight = ref.current.offsetHeight * this.dpr;
      const newWidth = ref.current.offsetWidth * this.dpr;

      let change = false;

      if (newHeight !== this.height) {
        this.height = newHeight;
        change = true;
      }

      if (newWidth !== this.width) {
        this.width = newWidth;
        change = true;
      }

      if (change) {
        this.emit('changeWindowSize');
      }
    }
  }

  data() {
    this.reevaluateDpr();

    return {
      center: this.center,
      zoom: this.zoom,
      zoomFactor: this.zoomFactor,
      width: this.width,
      height: this.height,
      dpr: this.dpr,
    };
  }

  get scale() {
    this.reevaluateDpr();
    return this.zoomFactor / this.dpr;
  }

  cancelCurrentAnimation() {
    if (this.currentAnimation) {
      this.currentAnimation.cancel();
      cancelAnimationFrame(this.currentAnimationFrame);
      this.currentAnimation = null;
    }
  }

  updateTo({ point, zoom, zoomFactor }) {
    if (zoomFactor) {
      zoom = zoomFactorToZoom(zoomFactor);
    }
    if (zoom) {
      this.zoom = clamp(this.minZoom, this.maxZoom, zoom);
      this.zoomFactor = zoomToZoomFactor(this.zoom);
    }
    if (point instanceof Point) this.center = point;

    this.emit('change');
  }

  async animateTo({ point, zoom }, ms, doEase = true) {
    if (this.currentAnimation) {
      this.cancelCurrentAnimation();
    }

    if (!zoom) zoom = this.zoom;
    if (!(point instanceof Point)) point = this.center;

    const startPoint = this.center;
    const startZoom = this.zoom;
    const start = Date.now();

    return new Promise(done => {
      this.currentAnimation = {
        cancel: done,
      };
      const frame = () => {
        let t = clamp(0, (Date.now() - start) / ms, 1);
        if (doEase) {
          t = ease(t);
        }
        this.updateTo({
          point: startPoint.lerp(point, t),
          zoom: lerp(startZoom, zoom, t),
        });

        this.emit('change');

        if (t < 1) {
          this.currentAnimationFrame = requestAnimationFrame(frame);
        } else {
          done();
        }
      };
      frame();
    });
  }

  toBoardSpace(screenPoint) {
    let { width, height, center, dpr } = this.data();
    return new Point(
      (screenPoint.x * dpr - width / 2) / this.zoomFactor + center.x,
      (screenPoint.y * dpr - height / 2) / this.zoomFactor + center.y
    );
  }

  distanceInBoardSpace(distance) {
    return distance.scale(this.dpr / this.zoomFactor);
  }

  toScreenSpace(boardPoint) {
    let { width, height, center, dpr } = this.data();
    return new Point(
      ((boardPoint.x - center.x) * this.zoomFactor + width / 2) / dpr,
      ((boardPoint.y - center.y) * this.zoomFactor + height / 2) / dpr
    );
  }
}

export class CanvasLayer extends Component {
  constructor() {
    super();

    this.canvas = createRef();
  }

  maybeDrawCanvas() {
    if (!this.needsDrawCanvas) {
      this.needsDrawCanvas = true;
      requestAnimationFrame(() => this.drawCanvas());
    }
  }

  drawCanvas() {
    this.needsDrawCanvas = false;

    if (!this.props.camera || !this.canvas.current) {
      return;
    }

    return true;
  }

  resizeCanvas() {
    if (!this.props.camera || !this.canvas.current) {
      return;
    }

    let { width, height } = this.props.camera.data();
    this.canvas.current.width = width;
    this.canvas.current.height = height;

    this.drawCanvas();
  }
}

export class DomLayer extends Component {
  constructor() {
    super();
    this.domElement = createRef();
  }

  maybeUpdateDom() {
    if (!this.needsDomUpdate) {
      this.needsDomUpdate = true;
      requestAnimationFrame(() => this.updateDom());
    }
  }

  updateDom() {
    this.needsDomUpdate = false;

    if (!this.props.camera || !this.domElement.current) {
      return;
    }

    return true;
  }
}

export const LayerStackContext = createContext({
  camera: null,
  eventBus: null,
  restoreCamera: () => {},
  saveCamera: () => {},
});

export class LayerStack extends Component {
  constructor({ zoom, minZoom, maxZoom }) {
    super();

    this.camera = new Camera({ zoom, minZoom, maxZoom });

    this.layerStack = createRef();

    // Not state, so don't use any react hooks
    this.currentPinch = null;
    this.currentPan = null;
    this.savedCamera = null;
    this.nextCamera = null;

    this.eventBus = new EventBus([
      'click',
      'mousedown',
      'mouseleave',
      'mousemove',
      'mouseup',
      'pan',
      'wheel',
    ]);

    this.saveCamera = this.saveCamera.bind(this);
    this.restoreCamera = this.restoreCamera.bind(this);
  }

  saveCamera() {
    this.savedCamera = this.camera.data();
  }

  restoreCamera() {
    this.nextCamera = this.savedCamera;
    this.savedCamera = null;
  }

  measure() {
    this.camera.reevaluateSize(this.layerStack);
  }

  componentDidUpdate() {
    if (this.nextCamera) {
      this.camera.animateTo(
        {
          point: this.nextCamera.center,
          zoom: this.nextCamera.zoom,
        },
        300
      );
      this.nextCamera = null;
    }

    this.measure();
  }

  componentDidMount() {
    if (this.layerStack.current) {
      // Attach passive event listeners to the canvas for scrolling events that will never actually scroll the page
      this.layerStack.current.addEventListener('wheel', this.onWheel.bind(this), {
        passive: true,
      });
      this.layerStack.current.addEventListener('touchstart', this.onTouchStart.bind(this), {
        passive: true,
      });
      this.layerStack.current.addEventListener('touchmove', this.onTouchMove.bind(this), {
        passive: true,
      });
    } else {
      console.warn('could not attach passive scroll-related listeners to canvas');
    }

    this.measure();
  }

  // --- panning logic

  maybeStartPan(e) {
    if (this.currentPan || this.currentPinch) {
      return false;
    }

    if (e.touches && e.touches.length === 1) {
      this.startPan({
        startPoint: Point.fromMouseEvent(e.changedTouches[0]),
        source: e.changedTouches[0].identifier,
      });
      return true;
    }

    if (!e.touches) {
      this.startPan({
        startPoint: Point.fromMouseEvent(e),
        source: 'mouse',
      });
      return true;
    }

    return false;
  }

  startPan({ startPoint, source }) {
    this.currentPan = {
      startPoint,
      currentPoint: startPoint,
      startPointBoard: this.camera.center,
      source,
      distanceTraveled: 0,
    };
  }

  maybeContinuePan(e) {
    if (!this.currentPan || this.currentPinch) {
      return false;
    }

    let shouldContinue = false;
    let currentPoint;

    if (e.touches && e.touches.length === 1) {
      if (e.changedTouches[0].identifier === this.currentPan.source) {
        shouldContinue = true;
        currentPoint = Point.fromMouseEvent(e.changedTouches[0]);
      }
    }

    if (!e.touches) {
      if ('mouse' === this.currentPan.source) {
        shouldContinue = true;
        currentPoint = Point.fromMouseEvent(e);
      }
    }

    if (shouldContinue) {
      this.continuePan({ point: currentPoint });
      return true;
    } else {
      this.stopPan();
      return false;
    }
  }

  continuePan({ point }) {
    if (!this.currentPan) {
      return;
    }

    const delta = this.currentPan.startPoint.subtract(point);

    this.currentPan.currentPoint = point;
    this.currentPan.distanceTraveled += delta.length();

    const boardSpaceDelta = this.camera.distanceInBoardSpace(delta);

    this.camera.updateTo({
      point: this.currentPan.startPointBoard.add(boardSpaceDelta),
    });
  }

  stopPan() {
    this.lastPan = this.currentPan;
    this.currentPan = null;
  }

  // --- pinching logic

  maybeStartPinch(e) {
    if (this.currentPinch) {
      return false;
    }

    if (e.touches && e.touches.length === 2) {
      let pointOne = Point.fromMouseEvent(e.touches[0]);
      let pointTwo = Point.fromMouseEvent(e.touches[1]);

      this.stopPan();
      this.startPinch({
        startDistance: pointOne.distanceTo(pointTwo),
        sources: new Set([e.touches[0].identifier, e.touches[1].identifier]),
      });
      return true;
    }

    return false;
  }

  startPinch({ startDistance, sources }) {
    this.currentPinch = {
      startDistance,
      startZoomFactor: this.camera.zoomFactor,
      sources,
    };
  }

  maybeContinuePinch(e) {
    if (!this.currentPinch) {
      return;
    }

    if (e.touches.length === 2) {
      if (
        this.currentPinch.sources.has(e.touches[0].identifier) &&
        this.currentPinch.sources.has(e.touches[1].identifier)
      ) {
        const pointOne = Point.fromMouseEvent(e.touches[0]);
        const pointTwo = Point.fromMouseEvent(e.touches[1]);

        this.continuePinch({ distance: pointOne.distanceTo(pointTwo) });
        return true;
      }
    }

    this.stopPinch();
    return false;
  }

  continuePinch({ distance }) {
    if (!this.currentPinch) {
      return;
    }

    const ratio = distance / this.currentPinch.startDistance;

    this.camera.updateTo({
      zoomFactor: this.currentPinch.startZoomFactor * ratio,
    });
  }

  stopPinch() {
    this.currentPinch = null;
  }

  // --- event listeners

  onTouchStart(e) {
    this.maybeStartPan(e) || this.maybeStartPinch(e);

    // // Because you may have just added a finger, call maybeContinue methods
    // // which will stop the current pan or pinch if needed
    // this.maybeContinuePan(e);
    // this.maybeContinuePinch(e);
  }

  onTouchMove(e) {
    this.maybeContinuePan(e) || this.maybeContinuePinch(e);
  }

  onTouchEnd(e) {
    // Because you may have just removed a finger, you want to
    // continue any pan or pinch currently in progress (if applicable)
    // and then stop any pan or pinch in progress

    this.maybeContinuePan(e);
    this.stopPan();

    this.maybeContinuePinch(e);
    this.stopPinch();
  }

  onMouseDown(e) {
    const isPanning = this.maybeStartPan(e);

    if (!isPanning) {
      let mouseCurrent = Point.fromMouseEvent(e);
      let boardPoint = this.camera.toBoardSpace(mouseCurrent);

      this.eventBus.emit('mousedown', boardPoint);
    }
  }

  onMouseMove(e) {
    const isPanning = this.maybeContinuePan(e);

    if (!isPanning) {
      let mouseCurrent = Point.fromMouseEvent(e);
      let boardPoint = this.camera.toBoardSpace(mouseCurrent);
      this.eventBus.emit('mousemove', boardPoint);
    }
  }

  onMouseUp(e) {
    const isPanning = this.maybeContinuePan(e);

    if (isPanning) {
      this.stopPan();
    } else {
      // we're not really sure if we should mouseup if we were panning.
      // we definitely want to mouseup if we weren't panning.
      // we're not sure if anyone would use this in either case.
      let mouseCurrent = Point.fromMouseEvent(e);
      let boardPoint = this.camera.toBoardSpace(mouseCurrent);
      this.eventBus.emit('mouseup', boardPoint);
    }
  }

  onMouseLeave(_e) {
    this.stopPan();
    this.eventBus.emit('mouseleave');
  }

  onClick(e) {
    if (!this.lastPan || this.lastPan.distanceTraveled < 10) {
      let mouseCurrent = Point.fromMouseEvent(e);
      let boardPoint = this.camera.toBoardSpace(mouseCurrent);
      this.eventBus.emit('click', boardPoint);
    }
  }

  onWheel(e) {
    // because this is registered as a passive event listener, we're not actually allowed to preventDefault
    // e.preventDefault();
    setZoom(this.camera.zoom + e.deltaY / 16);
    // Not emitting here for performance, maybe we debounce and reenable this
    // this.eventBus.emit('wheel');
  }

  render({ children }) {
    const layerStackContext = {
      camera: this.camera,
      eventBus: this.eventBus,
      restoreCamera: this.restoreCamera,
      saveCamera: this.saveCamera,
    };

    // These event listeners used to be attached diretly to the <canvas> element but I switched them
    // to direct `addEventListener` calls with the canvas ref so that I could supply { passive: true }
    // since these events should always preventDefault() and will never actually scroll the page:
    //   onwheel=${e => this.onWheel(e)}
    //   ontouchstart=${e => this.onTouchStart(e)}
    //   ontouchmove=${e => this.onTouchMove(e)}
    return html`
      <div
        class="canvas-grid"
        ref=${this.layerStack}
        onmousedown=${e => this.onMouseDown(e)}
        onmouseup=${e => this.onMouseUp(e)}
        onmousemove=${e => this.onMouseMove(e)}
        ontouchend=${e => this.onTouchEnd(e)}
        onclick=${e => this.onClick(e)}
        onmouseleave=${e => this.onMouseLeave(e)}
      >
        <${LayerStackContext.Provider} value=${layerStackContext}>
          ${children}
        </LayerStackContext.Provider>
      </div>
    `;
  }
}
