import { html, Component } from 'htm/preact';
import {
  Point,
  CanvasLayer,
  DomLayer,
  LayerStack,
  LayerStackContext,
} from '../../interfaces/graphics';
import Meepler from './Meepler';
import { FLAG_CLOTH, FLAG_POLE } from './flag';
import { meeplePath } from './meeple';
import { GfxCanvas, RAD } from '../../utils/gfx';
import { getTileAsset, meeplePosition } from './Tile';

const CSS = getComputedStyle(document.documentElement);
const ColorSuccess = CSS.getPropertyValue('--color-success');
const ColorError = CSS.getPropertyValue('--color-error');

const MEEPLE = new Path2D(meeplePath);
const CURSOR = new Path2D('M0,0 L20,20 0,28z');

const TILE_SIZE = 128;
const GAP = 2;
const GRID_SIZE = TILE_SIZE + GAP;

function fromTileSpaceToBoardSpace(tilePoint) {
  return new Point(tilePoint.x * GRID_SIZE, -tilePoint.y * GRID_SIZE);
}

function fromBoardSpaceToTileSpace(boardPoint) {
  return new Point(Math.round(boardPoint.x / GRID_SIZE), Math.round(-boardPoint.y / GRID_SIZE));
}

const key = (x, y) => x + ',' + y;
const fromKey = key => {
  let [x, y] = key.split(',').map(int => Math.trunc(int));
  return { x, y };
};
const getNeighbors = (tiles, x, y) => [
  tiles.get(key(x - 1, y)),
  tiles.get(key(x + 1, y)),
  tiles.get(key(x, y + 1)),
  tiles.get(key(x, y - 1)),
];

function getTileForRendering({ allTiles, assets, positionKey, gridCell }) {
  const { x, y } = fromKey(positionKey);
  const { tileId, orientation, meeple, currentGame } = gridCell;

  const asset = getTileAsset(assets, allTiles[tileId]);
  if (!asset) {
    console.warn('no tile asset found for', allTiles[tileId]);
  }

  return {
    type: 'tile',
    coords: { x, y },
    asset,
    tile: allTiles[tileId],
    orientation,
    meeple,
    currentGame,
  };
}

function getPreviousPlayerTiles(previousPlayerTileCoords) {
  return Object.entries(previousPlayerTileCoords).reduce(
    (memo, [playerId, coords]) => ({
      ...memo,
      [key(coords.x, coords.y)]: playerId,
    }),
    {}
  );
}

function getDataForRendering({
  allTiles,
  assets,
  grid,
  meepleHistory,
  onClearPlaceCheck,
  onPlace,
  players,
  potentialPlacement,
  previousPlayerTileCoords,
}) {
  if (!assets || !allTiles || !players) {
    return null;
  }

  const previousPlayerTiles = getPreviousPlayerTiles(previousPlayerTileCoords);

  // meepleHistory item structure:
  // {
  //   /* The following props are appled to all meeples ever placed on the board */
  //   coords: { x: 0, y: 1 },
  //   position: "center",
  //   playerId: "8a5a4be3-02ba-4cd8-90da-4a00eadf3980",
  //
  //   /* The following props are appled to a meeple after it's removed from the board */
  //   scored: true,
  //   scoredElement: "playground",
  //   completed: true, /* This is only false if the meeple is removed during end game */
  //   score: 10, /* Supposing a player scores a feature using mutiple meeples,
  //                 only one of their meeples will contain a non-zero score that
  //                 represents the total score of the feature. All other 'helper'
  //                 meeples will have score: 0. */
  // }
  const flags = meepleHistory
    .filter(({ scored, score }) => score && scored && score > 0) // Only show flags for scored meeples
    .map(meepleHistoryItem => ({
      color: players[meepleHistoryItem.playerId].color,
      coords: [meepleHistoryItem.coords.x, meepleHistoryItem.coords.y],
      pos: meeplePosition(meepleHistoryItem.position),
    }));

  let renderingTiles = new Map();

  for (let [positionKey, gridCell] of Object.entries(grid.data)) {
    const tile = getTileForRendering({ allTiles, assets, positionKey, gridCell });
    const previousPlayer = previousPlayerTiles[positionKey];

    renderingTiles.set(positionKey, {
      previousPlayer,
      ...tile,
    });
  }

  // we need to expand the board's width and height by one to allow for `PotentialTile` objects
  let minX = grid.minX - 1;
  let maxX = grid.maxX + 1;
  let minY = grid.minY - 1;
  let maxY = grid.maxY + 1;

  for (let y = maxY; y >= minY; y--) {
    for (let x = minX; x <= maxX; x++) {
      let positionKey = key(x, y);

      if (!renderingTiles.get(positionKey)) {
        let neighbors = getNeighbors(renderingTiles, x, y);
        if (neighbors.some(n => n && n.type === 'tile')) {
          let isPotential = false;
          let isValid = false;
          if (
            potentialPlacement &&
            potentialPlacement.coords.x === x &&
            potentialPlacement.coords.y === y
          ) {
            isPotential = true;
            isValid = potentialPlacement.valid;
          }

          let positionKey = key(x, y);
          renderingTiles.set(positionKey, {
            type: 'potential',
            coords: { x, y },
            onPlace,
            onClearPlaceCheck,
            isPotential: isPotential,
            potentialIsValid: isValid,
          });
        }
      }
    }
  }

  return {
    tiles: Array.from(renderingTiles.values()),
    flags,
    minX,
    minY,
    maxX,
    maxY,
    width: maxX - minX + 1,
    height: maxY - minY + 1,
  };
}

function getLastPlayerPlacement({ currentPlayer, grid, previousPlayerTileCoords }) {
  let lastPlayerPlacement = null;

  const previousPlayerTiles = getPreviousPlayerTiles(previousPlayerTileCoords);

  for (let [positionKey, _gridCell] of Object.entries(grid.data)) {
    const { x, y } = fromKey(positionKey);
    let previousPlayer = previousPlayerTiles[positionKey];
    if (previousPlayer === currentPlayer) {
      lastPlayerPlacement = { x, y };
    }
  }

  return lastPlayerPlacement;
}

class GameBoardLayer extends CanvasLayer {
  constructor() {
    super();

    this.hoverTilePoint = null; // the tile space (x, y) of your mouse RIGHT NOW
    this.isHoveringPotential = false;

    this.attachedCameraListeners = false;
    this.attachedEventBusListeners = false;
  }

  maybeAttachListeners() {
    if (!this.attachedCameraListeners && this.props.camera) {
      this.attachedCameraListeners = true;
      this.props.camera.on('change', () => this.maybeDrawCanvas());
      this.props.camera.on('changeWindowSize', () => this.resizeCanvas());
    }

    if (!this.attachedEventBusListeners && this.props.eventBus) {
      this.attachedEventBusListeners = true;
      this.props.eventBus.on('click', boardPoint => this.onClick(boardPoint));
      this.props.eventBus.on('mousemove', boardPoint => this.onMouseMove(boardPoint));
    }
  }

  componentDidMount() {
    this.maybeAttachListeners();
    this.maybeDrawCanvas();
  }

  componentDidUpdate() {
    this.maybeAttachListeners();
    this.maybeDrawCanvas();
  }

  onClick(boardPoint) {
    let tilePoint = fromBoardSpaceToTileSpace(boardPoint);
    for (let tile of this.dataForRendering.tiles) {
      if (tile.type === 'potential' && tilePoint.equals(tile.coords)) {
        this.props.onPlace({ x: tilePoint.x, y: tilePoint.y });
        break;
      }
    }
  }

  onMouseMove(boardPoint) {
    let tilePoint = fromBoardSpaceToTileSpace(boardPoint);

    if (!this.hoverTilePoint || !this.hoverTilePoint.equals(tilePoint)) {
      this.hoverTilePoint = tilePoint;

      let isNewlyHoveringPotential = false;
      for (let tile of this.dataForRendering.tiles) {
        if (tile.type === 'potential' && tilePoint.equals(tile.coords)) {
          isNewlyHoveringPotential = true;
          this.isHoveringPotential = true;
          this.props.onPlace({ x: tilePoint.x, y: tilePoint.y }, true);
          break;
        }
      }
      if (!isNewlyHoveringPotential) {
        if (this.isHoveringPotential) {
          this.props.onClearPlaceCheck();
        }
        this.isHoveringPotential = false;
      }
    }
  }

  drawCanvas() {
    if (!super.drawCanvas()) return;

    if (!this.dataForRendering) return;
    const { center, width, height, zoomFactor } = this.props.camera.data();

    let ctx = this.canvas.current.getContext('2d');
    let gfx = new GfxCanvas(ctx);

    ctx.clearRect(0, 0, width, height);
    gfx.transform(ctx => {
      ctx.translate(width / 2, height / 2);
      ctx.scale(zoomFactor, zoomFactor);
      ctx.translate(-center.x, -center.y);
      for (let tile of this.dataForRendering.tiles) {
        let { x, y } = tile.coords;
        gfx.transform(ctx => {
          ctx.translate(GRID_SIZE * x + GAP / 2, GRID_SIZE * -y + GAP / 2);
          if (tile.type === 'tile' && tile.asset && tile.asset.image) {
            gfx.rect({ rotate: tile.orientation * 90 * RAD, size: TILE_SIZE }, ctx => {
              ctx.drawImage(tile.asset.image, 0, 0, TILE_SIZE, TILE_SIZE);
              if (tile.currentGame === false) {
                ctx.globalCompositeOperation = 'color';
                ctx.fillStyle = 'rgba(0,0,0,.5)';
                ctx.fillRect(0, 0, TILE_SIZE, TILE_SIZE);
                ctx.globalCompositeOperation = 'source-over';
              }
            });
            if (tile.meeple) {
              gfx.rect({ size: TILE_SIZE / 3, scale: TILE_SIZE / 3 / 250 }, ctx => {
                ctx.fillStyle = this.props.players[tile.meeple.player].color;
                let [ox, oy] = meeplePosition(tile.meeple.position);
                ctx.translate(ox * 200, oy * 200);
                ctx.fill(MEEPLE);
                ctx.lineWidth = 12;
                ctx.stroke(MEEPLE);
              });
            }
            if (tile.previousPlayer) {
              ctx.strokeStyle = this.props.players[tile.previousPlayer].color;
              ctx.lineWidth = 4;
              ctx.strokeRect(-TILE_SIZE / 2 + 2, -TILE_SIZE / 2 + 2, TILE_SIZE - 4, TILE_SIZE - 4);
            }
          } else if (tile.type === 'potential') {
            ctx.fillStyle = 'rgba(128,128,128,.5)';
            if (tile.isPotential) {
              if (tile.potentialIsValid) {
                ctx.fillStyle = ColorSuccess;
              } else {
                ctx.fillStyle = ColorError;
              }
            }
            ctx.fillRect(-TILE_SIZE / 2, -TILE_SIZE / 2, TILE_SIZE, TILE_SIZE);
          } else {
            console.log('unknown tile type', tile.type);
          }
        });
      }

      this.dataForRendering.flags.forEach(flag => {
        let [x, y] = flag.coords;
        gfx.transform(ctx => {
          ctx.translate(GRID_SIZE * x + GAP / 2, GRID_SIZE * -y + GAP / 2);
          gfx.rect(
            // flag seems to look good at 1/3 tilesize
            // flag vector size is originally 480px, hence scale factor
            { size: TILE_SIZE / 3, scale: TILE_SIZE / 3 / 480 },
            ctx => {
              ctx.fillStyle = flag.color;
              ctx.lineWidth = 20;
              let [ox, oy] = flag.pos;
              ctx.translate(ox * 480 + 60, oy * 480 - 200);
              ctx.fill(FLAG_CLOTH);
              ctx.stroke(FLAG_CLOTH);
              ctx.lineWidth = 32;
              ctx.fillStyle = ctx.strokeStyle = '#682B00';
              ctx.fill(FLAG_POLE);
              ctx.stroke(FLAG_POLE);
            }
          );
        });
      });
    });
  }

  render({
    allTiles,
    assets,
    currentPlayer,
    grid,
    players,
    potentialPlacement,
    previousPlayerTileCoords,
    onPlace,
    onClearPlaceCheck,
    meepleHistory,
  }) {
    this.dataForRendering = getDataForRendering({
      allTiles,
      assets,
      currentPlayer,
      grid,
      players,
      potentialPlacement,
      previousPlayerTileCoords,
      onPlace,
      onClearPlaceCheck,
      meepleHistory,
    });

    return html`
      <canvas class="canvas-grid" ref=${this.canvas}></canvas>
    `;
  }
}

class CursorsLayer extends CanvasLayer {
  constructor() {
    super();

    this.lastCursorUpdate = 0;

    this.attachedCameraListeners = false;
    this.attachedEventBusListeners = false;
  }

  maybeAttachListeners() {
    if (!this.attachedCameraListeners && this.props.camera) {
      this.attachedCameraListeners = true;
      this.props.camera.on('change', () => this.maybeDrawCanvas());
      this.props.camera.on('changeWindowSize', () => this.resizeCanvas());
    }

    if (!this.attachedEventBusListeners && this.props.eventBus) {
      this.attachedEventBusListeners = true;
      this.props.eventBus.on('mousemove', boardPoint => this.onMouseMove(boardPoint));
      this.props.eventBus.on('mouseleave', () => this.onMouseLeave());
    }
  }

  componentDidMount() {
    this.maybeAttachListeners();
    this.maybeDrawCanvas();
  }

  componentDidUpdate() {
    this.maybeAttachListeners();
    this.maybeDrawCanvas();
  }

  onMouseMove(boardPoint) {
    if (Date.now() - this.lastCursorUpdate > 100) {
      this.props.setCursor({ x: boardPoint.x, y: boardPoint.y });
      this.lastCursorUpdate = Date.now();
    }
  }

  onMouseLeave() {
    this.props.setCursor({ x: null, y: null });
  }

  drawCanvas() {
    if (!super.drawCanvas()) {
      return;
    }

    const { cursors, players } = this.props;
    if (!cursors || !players) {
      return;
    }

    let { center, width, height, zoomFactor } = this.props.camera.data();

    let ctx = this.canvas.current.getContext('2d');
    let gfx = new GfxCanvas(ctx);
    ctx.clearRect(0, 0, width, height);
    gfx.transform(ctx => {
      ctx.translate(width / 2, height / 2);
      ctx.scale(zoomFactor, zoomFactor);
      ctx.translate(-center.x, -center.y);
      for (let [playerId, { x, y }] of Object.entries(cursors)) {
        if (x === null || y === null) continue;
        gfx.transform(ctx => {
          ctx.lineJoin = 'round';
          ctx.fillStyle = players[playerId].color || '#888';
          ctx.lineWidth = 4;
          ctx.strokeStyle = '#000';
          ctx.translate(x, y);
          ctx.stroke(CURSOR);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#fff';
          ctx.stroke(CURSOR);
          ctx.fill(CURSOR);
        });
      }
    });
  }

  render({}) {
    return html`
      <canvas class="canvas-grid canvas-grid--passthrough" ref="${this.canvas}"></canvas>
    `;
  }
}

class MeeplerLayer extends DomLayer {
  constructor({}) {
    super();

    this.attachedCameraListeners = false;
    // this.attachedEventBusListeners = false;
  }

  maybeAttachListeners() {
    if (!this.attachedCameraListeners && this.props.camera) {
      this.attachedCameraListeners = true;
      this.props.camera.on('change', () => this.maybeUpdateDom());
    }

    // if (!this.attachedEventBusListeners && this.props.eventBus) {
    //   this.attachedEventBusListeners = true;
    //   this.props.eventBus.on('mousemove', (boardPoint) => this.onMouseMove(boardPoint));
    // }
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.showMeepler && newProps.showMeepler) {
      newProps.saveCamera();
    }
    if (this.props.showMeepler && !newProps.showMeepler) {
      newProps.restoreCamera();
    }
  }

  componentDidMount() {
    this.maybeAttachListeners();
    this.maybeUpdateDom();
  }

  componentDidUpdate() {
    this.maybeAttachListeners();

    if (!this.props.camera) {
      return;
    }

    if (this.domElement.current && this.meeplerTile && this.props.showMeepler) {
      // Recenter board on meepler
      let boardPoint = fromTileSpaceToBoardSpace(this.meeplerTile.coords);
      this.props.camera.animateTo({ point: boardPoint, zoom: 7 }, 300);
    }

    this.maybeUpdateDom();
  }

  updateDom() {
    if (!super.updateDom()) {
      return;
    }

    // Position meepler
    let screenPoint = this.props.camera.toScreenSpace(
      fromTileSpaceToBoardSpace(
        new Point(this.meeplerTile.coords.x - 0.5, this.meeplerTile.coords.y + 0.5)
      )
    );

    let meepler = this.domElement.current;
    meepler.style.left = screenPoint.x + 'px';
    meepler.style.top = screenPoint.y + 'px';
    meepler.style.width = GRID_SIZE + 'px';
    meepler.style.height = GRID_SIZE + 'px';
    meepler.style.transform = `scale(${this.props.camera.scale})`;
  }

  render({
    allowedMeepleLocations,
    allTiles,
    assets,
    currentPlayer,
    grid,
    placeMeeple,
    players,
    previousPlayerTileCoords,
    showMeepler,
  }) {
    if (!showMeepler) {
      return;
    }

    let lastPlayerPlacement = getLastPlayerPlacement({
      currentPlayer,
      grid,
      previousPlayerTileCoords,
    });
    if (lastPlayerPlacement) {
      for (let [positionKey, gridCell] of Object.entries(grid.data)) {
        const tile = getTileForRendering({ allTiles, assets, positionKey, gridCell });
        if (tile.coords.x === lastPlayerPlacement.x && tile.coords.y === lastPlayerPlacement.y) {
          this.meeplerTile = tile;
          break;
        }
      }
    } else {
      this.meeplerTile = null;
      return;
    }

    return html`
      <div ref=${this.domElement} class="meepler-container">
        <${Meepler}
          placeMeeple=${placeMeeple}
          playerColor=${players[currentPlayer].color}
          style="width: ${TILE_SIZE}px; height:${TILE_SIZE}px"
          allowedMeepleLocations=${allowedMeepleLocations}
        />
      </div>
    `;
  }
}

class CanvasGrid extends Component {
  render({
    allowedMeepleLocations,
    allTiles,
    assets,
    currentPlayer,
    cursors,
    grid,
    meepleHistory = [],
    onClearPlaceCheck,
    onPlace,
    placeMeeple,
    players,
    potentialPlacement,
    previousPlayerTileCoords,
    setCursor,
    showMeepler,
  }) {
    return html`
      <${LayerStack}>
        <${LayerStackContext.Consumer}>
          ${({ camera, eventBus, restoreCamera, saveCamera }) => html`
            <${GameBoardLayer}
              eventBus=${eventBus}
              camera=${camera}
              allowedMeepleLocations=${allowedMeepleLocations}
              allTiles=${allTiles}
              assets=${assets}
              currentPlayer=${currentPlayer}
              grid=${grid}
              meepleHistory=${meepleHistory}
              onClearPlaceCheck=${onClearPlaceCheck}
              onPlace=${onPlace}
              placeMeeple=${placeMeeple}
              players=${players}
              potentialPlacement=${potentialPlacement}
              previousPlayerTileCoords=${previousPlayerTileCoords}
              showMeepler=${showMeepler}
            />
            <${CursorsLayer}
              eventBus=${eventBus}
              camera=${camera}
              cursors=${cursors}
              players=${players}
              setCursor=${setCursor}
            />
            <${MeeplerLayer}
              eventBus=${eventBus}
              camera=${camera}
              allowedMeepleLocations=${allowedMeepleLocations}
              allTiles=${allTiles}
              assets=${assets}
              currentPlayer=${currentPlayer}
              grid=${grid}
              placeMeeple=${placeMeeple}
              players=${players}
              previousPlayerTileCoords=${previousPlayerTileCoords}
              restoreCamera=${restoreCamera}
              saveCamera=${saveCamera}
              showMeepler=${showMeepler}
            />
          `}
        </LayerStackContext.Consumer>
      </LayerStack>
    `;
  }
}

export default CanvasGrid;
