import { html } from 'htm/preact';

export const meeplePath =
  'M24 234c-2-2-3-2-3-9 0-12 7-28 24-59 16-27 18-35 13-37l-15-2c-21-1-27-4-31-13-3-9-1-17 8-26 8-9 25-17 50-26l17-6 1-7c2-22 10-35 24-39 9-2 15-2 23 0 15 4 23 17 25 39l1 7 17 6c44 15 62 30 59 47-1 3-2 7-4 9-3 6-9 7-25 8-18 2-21 3-21 8 0 4 6 16 17 34 16 30 23 48 23 58-1 9-1 9-38 9h-31l-4-3c-2-2-8-10-12-18-11-18-15-23-18-23s-7 5-18 23l-12 18-4 3H58l-34-1z';

export const meeple = color => html`
  <svg viewBox="0 0 250 250" class="meeple">
    <path fill="${color}" d=${meeplePath} />
  </svg>
`;
