import { html, Component } from 'htm/preact';
import { route } from 'preact-router';

import { addToast, setAccount } from '../actions';

import Socket from '../interfaces/socket';

export default class Logout extends Component {
  componentWillMount() {
    Socket.rpc({ type: 'account:logout' })
      .then(({ type: responseType, data: responseData }) => {
        setAccount(null);
        window.location = '/';
      })
      .catch(error => {
        console.error(error);
        addToast({ type: 'error', message: 'Something went wrong' });
        route('/');
      });
  }

  render() {
    return html``;
  }
}
