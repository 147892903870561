import { html, Component } from 'htm/preact';

export default class Settings extends Component {
  static registeredSettings() {
    return [];
  }

  render() {
    return html`
      <div class="settings">cool</div>
    `;
  }
}
