import { html, Component } from 'htm/preact';
import { createRef } from 'preact';
import { animate } from '../../utils';

const SCORE_TIMEOUT = 2000;
const MAX_MEEPLES = 7;

export default class PositionTracker extends Component {
  constructor() {
    super();
    // this.scoreUpdates = [];
  }

  componentWillReceiveProps(nextProps, nextState) {
    // if (nextProps.scoreUpdates && Object.keys(nextProps.scoreUpdates).length > 0) {
    //   this.setState({ recentPlayerScoreUpdates: nextProps.scoreUpdates });
    // }
  }

  componentDidUpdate() {
    // if (this.scoreUpdates.length) {
    //   Promise.all(
    //     this.scoreUpdates.map(
    //       scoreUpdateRef => animate(scoreUpdateRef.current, 'score', SCORE_TIMEOUT)
    //     )
    //   ).then(() => {
    //     this.hideRecentPlayerScoreUpdates();
    //   });
    // }
  }

  hideRecentPlayerScoreUpdates() {
    // this.setState({ recentPlayerScoreUpdates: {} })
  }

  // positionTracker: {
  //   maxPosition: 9,
  //   scoring: [
  //     { position: 9, score: 7 },
  //     { position: 8, score: 6 },
  //     { position: 6, score: 5 },
  //     { position: 4, score: 4 },
  //     { position: 2, score: 3 },
  //   ],
  // }

  render({ positionTracker, players }, { recentPlayerScoreUpdates = {} }) {
    const { maxPosition, scoring } = positionTracker;

    return html`
      <div id="position-tracker">
        ${[...Array(maxPosition).keys()]
          .map(p => p + 1)
          .map(position => {
            const playersAtPosition = players.filter(player => player.position === position);
            const scoringMilestone = positionTracker.scoring.find(
              milestone => milestone.position === position
            );
            return html`
              <div class="position ${scoringMilestone ? 'milestone' : ''}">
                <div class="position-number">${position}</div>
                <div class="position-players">
                  ${playersAtPosition.map(
                    player => html`
                      <div style="color:${player.color};">${player.name}</div>
                    `
                  )}
                </div>
                ${scoringMilestone &&
                html`
                  <div class="position-score">${scoringMilestone.score}</div>
                `}
              </div>
            `;
          })}
      </div>
    `;

    // this.scoreUpdates = [];
    // let wideEnoughForMeepleBar = window.matchMedia('(min-width: 600px)').matches;
    // return html`
    //   <div id="player-tracker">
    //     <table>
    //       <tr><th>Player</th><th>Score</th><th>Meeples</th></tr>
    //       ${playerOrder.map(name => {
    //         let player = players.find(player => player.name === name);
    //         let recentPlayerScoreUpdate = recentPlayerScoreUpdates[name]|| 0;
    //         let previousScore = player.score - recentPlayerScoreUpdate;
    //
    //         let scoreUpdateRef;
    //         if (recentPlayerScoreUpdate > 0) {
    //           scoreUpdateRef = createRef();
    //           this.scoreUpdates.push(scoreUpdateRef);
    //         }
    //
    //         let isTurn = player.state === 'place-meeple' ||
    //                      player.state === 'place-tile';
    //
    //         return html`
    //           <tr>
    //             <td style="color:${player.color}">
    //               <div class="player-marker ${isTurn && 'currentPlayer'}" style="background-color:${player.color};"></div>
    //               ${player.name}
    //             </td>
    //             <td>
    //               ${recentPlayerScoreUpdate > 0 && html`
    //                 <span class="score-update" ref=${scoreUpdateRef}>+${recentPlayerScoreUpdate}</span>
    //               `}
    //               ${player.score}
    //             </td>
    //           </tr>
    //         `;
    //       })}
    //     </table>
    //   </div>
    // `;
  }
}
