import { html, Component } from 'htm/preact';

import { setAppMode } from '../actions';

import Header from '../components/Header';

export default class About extends Component {
  constructor(props) {
    super(props);

    setAppMode('about');
  }

  render({}) {
    return html`
      <div>
        <${Header} title="About" />
        <div class="about__container">
          <section class="authors">
            <p>
              Game room is a passion project built and designed by four friends trying to
              survive COVID19 quarantine with our sanity intact:

              <ul>
                <li><a href="https://potch.me">Potch</a></li>
                <li>Nick West</li>
                <li><a href="https://andrew.pariser.com">Andrew Pariser</a></li>
                <li><a href="http://rebeccapariser.com">Rebecca Pariser</a></li>
              </ul>
            </p>
            <p>
              If you want to support future entertaining products like this,
              consider <a href="https://ko-fi.com/gameroom">buying us a coffee</a> or <a href="https://forms.gle/Te8Arid14H3a3SeU9">contacting us</a> with
              your feedback, suggestions, or random observations.
            </p>
          </section>

          <section class="attribution">
            <p>
              Additional credit goes to:
              <ul>
                <li>Sergi Solo for our <a href="https://unsplash.com/photos/Zif9OPYfwdE">menu background image</a></li>
                <li>Tim Foster for our <a href="https://unsplash.com/photos/y6uilkrSyMs">lobby background image</a></li>
                <li>notkoo, for our <a href="https://www.shutterstock.com/image-vector/cartoon-park-playground-icon-83735935">playground components</a></li>
                <li>the8monkey, for our <a href="https://www.shutterstock.com/image-vector/set-tree-house-children-playground-swing-1572222235">treehouse components</a></li>
              </ul>
            </p>
          </section>
        </div>
      </div>
    `;
  }
}
