import { html, Component } from 'htm/preact';
import { route, Link } from 'preact-router';

import { setAppMode } from '../actions';

import Header from '../components/Header';

import Socket from '../interfaces/socket';

import { getLastUserId } from '../localStorage';

export default class MainMenu extends Component {
  constructor(props) {
    super(props);

    setAppMode('main-menu');

    Socket.on('message', e => this.handleMessage(e), this);
  }

  componentWillUnmount() {
    Socket.scopeOff(this);
  }

  handleMessage({ type, data }) {
    if (type === 'room:created') {
      route(`/${data.code}`);
    }
  }

  joinRoom(e) {
    e.preventDefault();
    route(`/${e.target.elements.room.value}`);
  }

  createRoom(e, gameType) {
    e.preventDefault();

    let data = {
      id: getLastUserId(),
    };

    if (this.props.initQueryParams && this.props.initQueryParams.gameType) {
      data = { ...data, gameType: this.props.initQueryParams.gameType };
    }

    if (this.props.gameType) {
      data = { ...data, gameType: this.props.gameType };
    }

    if (gameType) {
      data = { ...data, gameType };
    }

    Socket.send({ type: 'room:create', data });
  }

  render() {
    return html`
      <div class="main-menu">
        <div class="main-menu__container">
          <${Header} title="Would you like to play a game?" alreadyHome />

          <div class="gr-options">
            <form class="gr-option" onSubmit=${e => this.joinRoom(e)}>
              <label><input class="gr-input" name="room" autocorrect="off" autocapitalize="off" spellcheck="false" placeholder="ROOM CODE" autofocus /></label>
              <button class="gr-button" type="submit">Join Room</button>
            </form>
          </div>
          <div class="gr__games">
            <a href="#" tabindex="0" class="gr__game gr__game--meepletown" onClick=${e =>
              this.createRoom(e, 'Meepletown')}>
              <div class="gr__game__title">Meepletown</div>
            </a>
            <a href="#" tabindex="0" class="gr__game gr__game--exquisite" onClick=${e =>
              this.createRoom(e, 'Exquisite')}>
              <div class="gr__game__title">Exquisite Corpse</div>
            </a>
          </div>
        </div>
        <footer class="gr-footer"><${Link} href="/about">About</Link></footer>
      </div>
    `;
  }
}
