const validityStateToErrorMessages = (name, validityState) => {
  const errorMessages = [];

  /* This is the one error message I expect the form to validate */
  if (validityState.valueMissing) {
    errorMessages.push(`${name} is required`);
  }

  /* The following are "dumb" error messages that I just created */
  if (validityState.badInput) {
    errorMessages.push(`${name} is bad input`);
  }
  if (validityState.customError) {
    errorMessages.push(`${name} has a custom error`);
  }
  if (validityState.patternMismatch) {
    errorMessages.push(`${name} does not match the speciifed pattern`);
  }
  if (validityState.rangeOverflow) {
    errorMessages.push(`${name} is too large`);
  }
  if (validityState.rangeUnderflow) {
    errorMessages.push(`${name} is too small`);
  }
  if (validityState.stepMismatch) {
    errorMessages.push(`${name} is not an allowed value`);
  }
  if (validityState.tooLong) {
    errorMessages.push(`${name} is too long`);
  }
  if (validityState.tooShort) {
    errorMessages.push(`${name} is too short`);
  }
  if (validityState.typeMismatch) {
    errorMessages.push(`${name} is of the wrong type`);
  }

  return errorMessages;
};

export function htmlValidateForm(form) {
  const validationErrors = Array.from(form.elements).reduce((memo, formElement) => {
    const elementValidates = formElement.checkValidity();

    let errorMessages = validityStateToErrorMessages(formElement.name, formElement.validity);
    if (!elementValidates && errorMessages.length === 0) {
      errorMessages = [`${formElement.name} is invalid`];
    }

    return {
      ...memo,
      [formElement.name]: errorMessages,
    };
  }, {});

  return {
    valid: !Object.values(validationErrors).some(errors => errors.length > 0),
    validationErrors,
  };
}
