import { html, Component } from 'htm/preact';
import { route } from 'preact-router';
import { createRef } from 'preact';

import { setAppMode, addToast } from '../actions';

import { decamelize } from '../utils';
import AssetLoader from '../utils/assetLoader';

import Socket from '../interfaces/socket';

import Lobby from '../components/Lobby';
import Header from '../components/Header';

import Games from '../games';
import Settings from '../games/settings';

import { getLastUserId, getLastUserName, setLastUserName } from '../localStorage';

const INITIAL_STATE = {
  gameSettings: {},
};

export default class Room extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.gameOverResultsRef = createRef();
    this.gameOverMenuRef = createRef();

    Socket.on('message', e => this.handleMessage(e), this);
    Socket.on('firstMessage', _ => this.joinRoom(this.props.roomCode), this);

    this.joinRoom(this.props.roomCode);
  }

  handleMessage({ type, data }) {
    if (type === 'room:join') {
      let { user, room } = data;
      // This will be called on server restart even if the client thinks it's currently in a room
      this.setState({ ...INITIAL_STATE, user, room });
      this.loadGameAssets(room);
    }

    if (type === 'room:not-found') {
      addToast({ type: 'error', message: `Room "${data.code}" not found` });
      route('/');
    }

    if (type === 'room:update') {
      this.setState({
        room: data.room,
        gameSettings: data.room.gameSettings,
      });
    }

    if (type === 'room:leave') {
      route('/');
    }

    if (type === 'room:rematch') {
      route(`/${data.code}`);
    }

    if (type === 'room:cannot-start-game') {
      addToast({ type: 'error', message: `Can't start game: ${data.message}` });
    }

    if (type === 'room:vip') {
      addToast({ type: 'success', message: `You now are the room VIP!` });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props && nextProps && nextProps.roomCode !== this.props.roomCode) {
      this.joinRoom(nextProps.roomCode);
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      this.state &&
      this.state.room &&
      (!prevState.room || prevState.room.code !== this.state.room.code)
    ) {
      this.onRoomChange();
    }
  }

  componentWillUnmount() {
    if (this.state.room) {
      this.leaveRoom();
    }

    Socket.scopeOff(this);
  }

  isGameOver() {
    return this.state.room && this.state.room.state === 'game_finished';
  }

  joinRoom(code) {
    Socket.send({
      type: 'room:join',
      data: { code, userName: getLastUserName(), id: getLastUserId() },
    });
  }

  leaveRoom(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    Socket.send({ type: 'room:leave' });
  }

  beginRematch(e) {
    e.preventDefault();

    if (!this.state.room) {
      return;
    }
    Socket.send({ type: 'room:rematch' });
  }

  onRoomChange(newRoom = this.state.room) {
    this.loadGameAssets(newRoom);

    if (this.props.initQueryParams) {
      const registeredSettings = Settings[newRoom.gameType].registeredSettings();
      // Extract all settings accepted by this game
      const initQueryGameSettings = registeredSettings.reduce(
        (memo, setting) =>
          this.props.initQueryParams.hasOwnProperty(setting)
            ? { ...memo, [setting]: this.props.initQueryParams[setting] }
            : memo,
        {}
      );
      // Extract all settings that don't match the server's state
      const newGameSettings = Object.entries(initQueryGameSettings).reduce(
        (memo, [setting, value]) =>
          newRoom.gameSettings[setting] === value ? memo : { ...memo, [setting]: value },
        {}
      );

      // Any settings that don't match the server? Call server to change settings!
      if (Object.keys(newGameSettings).length > 0) {
        this.changeGameSettings(newGameSettings);
      }
    }
  }

  loadGameAssets(room) {
    if (!room) {
      return;
    }

    if (this.state.assetsLoading) {
      return;
    }

    // Let the server know that the assets are already loaded!
    if (this.state.assets && this.state.assetsReady) {
      Socket.send({ type: 'room:assets-loaded' });
      return;
    }

    // Mark that we're currently loading assets for this gameType
    this.setState({ assetsLoading: true, assetsReady: false });

    const progressUpdate = (done, total) => {
      this.setState({ assetLoadingProgress: { done, total } });
    };

    AssetLoader.loadManifestAndTree(
      `/games/${decamelize(room.gameType)}/assets.json`,
      progressUpdate
    )
      .then(({ assets, assetsReady }) => {
        this.setState({ assets, assetsReady, assetsLoading: false });

        if (assetsReady) {
          Socket.send({ type: 'room:assets-loaded' });
        }
      })
      .catch(error => console.error('could not preload game assets', error));
  }

  changeUserSettings(data) {
    if (data.name && data.name !== getLastUserName()) {
      setLastUserName(data.name);
    }

    if (!this.state.room) {
      return;
    }

    Socket.send({ type: 'user:change-settings', data });
  }

  changeGameSettings(data) {
    if (!this.state.room) {
      return;
    }

    this.setState({
      gameSettings: {
        ...this.state.gameSettings,
        ...data,
      },
    });

    Socket.send({ type: 'room:change-settings', data });
  }

  startGame() {
    if (!this.state.room) {
      return;
    }

    Socket.send({ type: 'game:start' });
  }

  showGameOver() {
    this.setState({ hideGameOver: false });
  }
  hideGameOver() {
    this.setState({ hideGameOver: true });
  }

  render(
    {},
    {
      user,
      room,

      assets,
      assetsReady,
      gameSettings,
      assetLoadingProgress,

      hideGameOver,
    }
  ) {
    // Connected to a room but waiting for the game to start? Lobby time...
    if (!room || room.state === 'waiting') {
      setAppMode('lobby');
      document.body.classList.remove('lock-zoom');

      const SettingsComponent = room ? Settings[room.gameType] : null;
      const canStartGame = room && room.canStartGame;
      return html`
        <${Lobby}
          user=${user}
          room=${room}
          canStartGame=${canStartGame}
          assetLoadingProgress=${assetLoadingProgress}
          startGame=${this.startGame.bind(this)}
          changeUserSettings=${this.changeUserSettings.bind(this)}
          gameSettingsComponent=${SettingsComponent}
          gameSettings=${gameSettings}
          changeGameSettings=${this.changeGameSettings.bind(this)}
        />
      `;
    }

    // Loading assets progress bar
    if (!assetsReady) {
      const { done = 0, total = 1 } = assetLoadingProgress || {};
      return html`
        <span style="color:#fff">Loading... finished ${done} of ${total}</span>
      `;
    }

    // We're in the game!

    setAppMode('game');
    document.body.classList.add('lock-zoom');

    const GameComponent = Games[room.gameType];
    return html`
      <div class="room">
        <div class="game-over ${this.isGameOver() && !hideGameOver ? '' : 'hidden'}">
          <div>
            <${Header} title="Game Over" />
          </div>

          <div class="game-over__results" ref=${this.gameOverResultsRef}></div>

          <div class="gr-options" ref=${this.gameOverMenuRef}>
            <button class="gr-option gr-button" type="submit" onClick=${this.leaveRoom.bind(this)}>
              Main Menu
            </button>
            <button
              class="gr-option gr-button"
              type="submit"
              onClick=${this.beginRematch.bind(this)}
            >
              Rematch
            </button>
          </div>
        </div>

        <!-- Here's the actual game -->
        <div class="the-game ${this.isGameOver() && !hideGameOver ? 'hidden' : ''}">
          <div id=${room.gameType.toLowerCase()}>
            <${GameComponent}
              key="the-game"
              user=${user}
              room=${room}
              assets=${assets}
              gameType=${room.gameType.toLowerCase()}
              gameOverResultsRef=${this.gameOverResultsRef}
              gameOverMenuRef=${this.gameOverMenuRef}
              showGameOver=${this.showGameOver.bind(this)}
              hideGameOver=${this.hideGameOver.bind(this)}
              initQueryParams=${this.props.initQueryParams}
            />
          </div>
        </div>
      </div>
    `;
  }
}
