const DEBUG = false;
const log = DEBUG ? console.log.bind(window.console) : () => {};

export default class PromiseQueue {
  constructor({ concurrency = 1 } = {}) {
    // for ordering, use a unique key;
    // store the keys in a queue and the actual promise info in a map by key
    this.queue = [];
    this.enqueuedPromises = new Map();

    this.pendingPromises = new Set();
    this.concurrency = concurrency;

    this.onQueueDrained;
    this.queueDrainedPromise = new Promise(resolve => {
      log('onQueueDrained');
      this.onQueueDrained = resolve;
    });

    this.onQueueComplete;
    this.queueCompletePromise = new Promise(resolve => {
      log('onQueueComplete');
      this.onQueueComplete = resolve;
    });
  }

  finished() {
    return this.queueCompletePromise;
  }

  enqueue(key, promise) {
    if (typeof promise !== 'function') {
      throw 'In using PromiseQueue, you must enequeue functions that return promises';
    }

    log(`PromiseQueue.enqueue ${key}`);
    const promiseQueuePromise = new Promise((resolve, reject) => {
      log(`PromiseQueue enqueueing key ${key}`);
      this.queue.push(key);
      this.enqueuedPromises.set(key, {
        key,
        promise,
        resolve,
        reject,
      });

      log(`PromiseQueue dequeue ater enqueueing key ${key}`);
      this.dequeue();
    });
  }

  // Pull the next promise off the queue
  dequeue() {
    if (this.pendingPromises.size >= this.concurrency) {
      log(
        `PromiseQueue NOT dequeueing promise; currently working on ${this.pendingPromises.size} with concurrency ${this.concurrency}`
      );
      return false;
    }

    const hasNextKey = this.queue.length > 0;
    if (!hasNextKey) {
      log(`PromiseQueue NOT dequeueing promise; nothing left in queue`);

      log(`PromiseQueue drained`);
      this.onQueueDrained();

      if (this.pendingPromises.size === 0) {
        log(`PromiseQueue completed, resolving finished promise`);
        this.onQueueComplete();
      }

      return false;
    }

    const nextKey = this.queue.shift();
    log(`PromiseQueue next key: ${nextKey}`);
    if (!this.enqueuedPromises.has(nextKey)) {
      log(`PromiseQueue NOT dequeueing promise; enqueuedPromises missing key ${nextKey}`);
      return false;
    }
    const nextEnqueued = this.enqueuedPromises.get(nextKey);
    this.enqueuedPromises.delete(nextKey);

    log(`PromiseQueue dequeueing promise with key ${nextKey}`);
    try {
      this.pendingPromises.add(nextKey);

      nextEnqueued
        .promise()
        .then(value => {
          log(`PromiseQueue promise with key ${nextKey} resolved`);
          this.pendingPromises.delete(nextKey);
          nextEnqueued.resolve(value);
          this.dequeue();
        })
        .catch(err => {
          log(`PromiseQueue promise with key ${nextKey} rejected`);
          this.pendingPromises.delete(nextKey);
          nextEnqueued.reject(err);
          this.dequeue();
        });
    } catch (err) {
      log(`PromiseQueue promise with key ${nextKey} exception ${err}`);
      this.pendingPromises.delete(nextKey);
      nextEnqueued.reject(err);
      this.dequeue();
    }

    return true;
  }
}
