import { Component } from 'htm/preact';

import { addToast } from '../actions';

import Audio from '../interfaces/audio';
import Socket from '../interfaces/socket';
import Notifications from '../interfaces/notifications.js';

import './game.css';

class Game extends Component {
  constructor(props) {
    super(props);

    this.suspended = false;

    this.state = {
      gameState: null,
    };

    this.audio = Audio;
    this.audio.loadSounds(this.props.assets.sounds);

    Socket.on('message', e => this.handleMessage(e), this);
    this.lastGameAction = {};

    this.requestGameState();

    window.suspendGame = () => {
      this.suspended = true;
    };
    window.saveState = () => {
      return this.state;
    };
    window.resumeState = state => {
      this.setState(state);
    };
  }

  showGameOver() {
    this.setState({ hideGameOver: false });
    this.props.showGameOver();
  }

  hideGameOver() {
    this.setState({ hideGameOver: true });
    this.props.hideGameOver();
  }

  componentWillUnmount() {
    Socket.scopeOff(this);
  }

  requestGameState() {
    this.sendGameAction({ type: 'game:state' });
  }

  handleMessage({ type, data }) {
    if (this.suspended) return;
    if (type === 'game:state') {
      const nextState = {
        gameState: data.state,
        ...(data.staticData ? { gameStaticData: data.staticData } : {}),
        ...(data.players ? { players: data.players } : {}),
      };
      this.componentWillReceiveGameState(nextState);
      this.setState(nextState);
    }

    if (type === 'game:state-delta') {
      const nextState = {};

      if (data.state && Object.keys(data.state).length) {
        nextState.gameState = { ...this.state.gameState, ...data.state };
      }
      if (data.staticData && Object.keys(data.staticData).length) {
        nextState.staticData = { ...this.state.staticData, ...data.staticData };
      }
      if (data.players && Object.keys(data.players).length) {
        if (!this.state.players) {
          nextState.players = data.players;
        } else {
          nextState.players = Object.assign(
            {},
            ...Object.entries(this.state.players).map(([playerId, player]) => ({
              [playerId]: { ...player, ...(data.players[playerId] || {}) },
            }))
          );
        }
      }

      this.componentWillReceiveGameState(nextState);
      this.setState(nextState);
    }

    if (type === 'game:player-reconnect') {
      this.addToast({
        type: 'success',
        message: `${data.player.name} reconnected`,
      });
    }

    if (type === 'game:player-disconnect') {
      this.addToast({
        type: 'error',
        message: `${data.player.name} disconnected`,
      });
    }

    if (type === 'game:your-turn') {
      if ('visibilityState' in document && document.visibilityState === 'hidden') {
        Notifications.maybeTriggerNotification({
          title: 'Game Room',
          body: `It's your turn`,
          // icon: '',
          // data: {},
          // actions: [],
        });
      } else {
        this.addToast({ type: 'success', message: `It's your turn!` });
      }

      this.yourTurn();
    }
  }

  // Hook to allow the game to do something special on user's turn
  yourTurn() {}

  addToast({ type, message }) {
    addToast({ type, message });
  }

  sendGameAction({ type, data }) {
    Socket.send({ type, data });
    this.lastGameAction = { type, data };
  }

  componentWillReceiveGameState(nextState) {
    /* interface may be overridden by game inheritor */
  }
}

export default Game;
