import { html } from 'htm/preact';
import { createPortal } from 'preact/compat';

import Game from '../game';

import CanvasGrid from './CanvasGrid';
import { Tile } from './Tile';
import Menu from './Menu';
import Stats from './Stats';
import GameOver from './GameOver';

import { zoomIn, zoomOut } from '../../actions/ui';

import './meepletown.css';

class Meepletown extends Game {
  constructor(props) {
    super(props);

    const { user, room, assets } = props;
    this.state = {
      ...this.state,
      userId: user.id,
      hideGameOver: false,
      cursors: {},
    };

    this.localStartTimeOfCurrentTurn = Date.now();

    document.body.addEventListener('keypress', e => {
      if (!e.metaKey) {
        if (e.key === '-') {
          zoomOut();
        }
        if (e.key === '=') {
          zoomIn();
        }
      }
    });
  }

  handleMessage({ type, data }) {
    super.handleMessage({ type, data });

    if (type === 'game:player-cursor') {
      this.setState({ cursors: Object.assign({}, this.state.cursors, data) });
    }

    if (type === 'game:score-update') {
      this.setState({ scoreUpdates: data.scoreUpdates });

      setTimeout(() => {
        this.setState({ scoreUpdates: null });
      }, 50);
    }
  }

  componentWillReceiveGameState(nextState) {
    const thisGameState = this.state.gameState || {};
    const nextGameState = nextState.gameState || {};

    // Check to see if the next turn has just started
    if (thisGameState.startTimeOfCurrentTurn !== nextGameState.startTimeOfCurrentTurn) {
      this.localStartTimeOfCurrentTurn = Date.now();
    }
  }

  myPlayerObject() {
    const { userId, players = {} } = this.state;

    return players[userId];
  }

  currentPlayerObject() {
    const {
      gameState: { currentPlayer },
      players = {},
    } = this.state;

    return players[currentPlayer];
  }

  gameOver(state = this.state) {
    return state && state.gameState && state.gameState.status === 'FINISHED';
  }

  yourTurn() {
    this.audio.playSound('patink');
  }

  myPlayerActive() {
    const {
      userId,
      gameState: { currentPlayer },
    } = this.state;

    return userId === currentPlayer;
  }

  canPlace() {
    return (
      !this.gameOver() && this.myPlayerActive() && this.currentPlayerObject().state === 'place-tile'
    );
  }

  canMeeple() {
    return (
      !this.gameOver() &&
      this.myPlayerActive() &&
      this.currentPlayerObject().state === 'place-meeple'
    );
  }

  rotate() {
    if (!this.canPlace()) {
      this.addToast({ type: 'error', message: 'You cannot rotate a tile now' });
      return;
    }

    if (!this.canPlace()) return;
    this.audio.playSound('pop');
    this.sendGameAction({ type: 'game:rotate-tile' });
  }

  place(position, check = false) {
    if (!this.canPlace()) {
      // this.addToast({ type: 'error', message: 'You cannot place a tile now' })
      return;
    }

    let type = check ? 'game:check-tile' : 'game:place-tile';
    let data = { newPosition: position };
    this.setState({
      lastTilePlacement: position,
    });

    this.sendGameAction({ type, data });
  }

  clearPlaceCheck() {
    if (!this.canPlace()) {
      // this.addToast({ type: 'error', message: 'You cannot place a tile now' })
      return;
    }

    this.sendGameAction({ type: 'game:clear-tile-check' });
  }

  placeMeeple(cellId) {
    if (!this.canMeeple()) {
      this.addToast({
        type: 'error',
        message: 'You cannot place a meeple now',
      });
      return;
    }

    this.sendGameAction({
      type: 'game:place-meeple',
      data: { newMeeplePosition: cellId },
    });
  }

  setCursor({ x, y }) {
    this.sendGameAction({
      type: 'game:player-cursor',
      data: { x, y },
    });
  }

  stashTile() {
    this.sendGameAction({ type: 'game:stash-tile' });
  }

  useStashTile(stashTileIndex) {
    this.sendGameAction({ type: 'game:use-stash-tile', data: { stashTileIndex } });
  }

  forfeit() {
    this.sendGameAction({ type: 'game:forfeit' });
  }

  render(
    { assets, gameOverResultsRef, gameOverMenuRef },
    { gameStaticData, gameState, hideGameOver, lastTilePlacement, cursors, scoreUpdates, players }
  ) {
    if (!gameState)
      return html`
        <div></div>
      `;

    const { allTiles, settings: gameSettings } = gameStaticData;

    const {
      allowedMeepleLocations,
      currentPlayer,
      currentTileId,
      currentTileOrientation,
      endGameAwards,
      grid,
      meepleHistory = [],
      potentialPlacement,
      playerOrder,
      previousPlayerTileCoords,
      remainingTiles,
    } = gameState;

    let myStashedTiles = (this.myPlayerObject() || {}).stashedTiles || [];
    let myStashFull = myStashedTiles.filter(s => s === null).length === 0;

    let canMeeple = this.canMeeple();
    let canPlace = this.canPlace();

    return html`
      ${!this.gameOver() &&
      html`
        <div class="tile-information">
          <div class="tile-information__remaining-tiles">Remaining Tiles: ${remainingTiles}</div>
          <div
            class="tile-information__current-tile ${canPlace || canMeeple ? 'active' : 'inactive'}"
            onClick=${this.rotate.bind(this)}
          >
            <${Tile}
              tile=${allTiles[currentTileId]}
              orientation=${currentTileOrientation}
              assets=${assets}
            />
          </div>
          ${gameSettings.stash &&
          html`
            <div class="tile-information__stashed-tiles">
              <button
                class="control-button"
                disabled=${myStashFull}
                onClick=${this.stashTile.bind(this)}
              >
                stash tile
              </button>
              ${myStashedTiles.map((tileId, i) =>
                tileId === null
                  ? null
                  : html`
                      <div
                        class="tile-information__stashed-tiles__tile"
                        onClick=${_ => this.useStashTile(i)}
                      >
                        <${Tile} tile=${allTiles[tileId]} assets=${assets} />
                      </div>
                    `
              )}
            </div>
          `}
        </div>
      `}

      <${CanvasGrid}
        allTiles=${allTiles}
        grid=${grid}
        game=${this}
        players=${players}
        currentPlayer=${currentPlayer}
        potentialPlacement=${potentialPlacement}
        previousPlayerTileCoords=${previousPlayerTileCoords}
        assets=${assets}
        meepleHistory=${meepleHistory}
        showMeepler=${canMeeple}
        meeplerLocation=${lastTilePlacement}
        allowedMeepleLocations=${canMeeple && allowedMeepleLocations}
        placeMeeple=${this.placeMeeple.bind(this)}
        onRotate=${this.rotate.bind(this)}
        onPlace=${this.place.bind(this)}
        onClearPlaceCheck=${this.clearPlaceCheck.bind(this)}
        setCursor=${this.setCursor.bind(this)}
        cursors=${cursors}
      />

      ${this.gameOver() &&
      html`
        <button class="gr-button show-game-over" onClick=${this.showGameOver.bind(this)}>
          Continue
        </button>
      `}

      <${Stats}
        players=${players}
        playerOrder=${playerOrder}
        scoreUpdates=${scoreUpdates}
        useChessTimer=${gameSettings.useChessTimer}
        localStartTimeOfCurrentTurn=${this.localStartTimeOfCurrentTurn}
        gameOver=${this.gameOver()}
      />

      <div id="controls">
        <${Menu} forfeit=${this.forfeit.bind(this)} addToast=${this.addToast.bind(this)} />
        <button class="control-button" onClick=${e => zoomIn()}>
          <svg style="width:.9em;height:.9em;" width="16" height="16" viewBox="0 0 16 16">
            <rect x="0" y="6.5" width="16" height="3" />
            <rect x="6.5" y="0" width="3" height="16" />
          </svg>
        </button>
        <button class="control-button" onClick=${e => zoomOut()}>
          <svg style="width:.9em;height:.9em;" width="16" height="16" viewBox="0 0 16 16">
            <rect x="0" y="6.5" width="16" height="3" />
          </svg>
        </button>
      </div>

      ${this.gameOver() &&
      !hideGameOver &&
      createPortal(
        html`
          <${GameOver} players=${players} endGameAwards=${endGameAwards} />
        `,
        gameOverResultsRef.current
      )}
      ${this.gameOver() &&
      !hideGameOver &&
      createPortal(
        html`
          <button class="gr-button gr-option" onClick=${this.hideGameOver.bind(this)}>
            Show Board
          </button>
        `,
        gameOverMenuRef.current
      )}
    `;
  }
}

export default Meepletown;
