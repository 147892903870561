import { html, Component } from 'htm/preact';

const NEW_CARD_STATE = {
  selected: [],
};

export default class Card extends Component {
  constructor() {
    super();

    this.state = {
      selected: [],
    };
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.cardIndex && nextProps.cardIndex !== this.props.cardIndex) {
      this.setState({ selected: [] });
    }

    if (nextProps.mode && nextProps.mode !== this.props.mode) {
      this.setState({ selected: [] });
    }
  }

  toggleSelection(choice) {
    if (this.props.mode === 'display') return;

    if (this.state.selected.includes(choice)) {
      this.setState({
        selected: this.state.selected.filter(c => c !== choice),
      });
    } else {
      this.setState({ selected: [...this.state.selected, choice] });
    }
  }

  lockGuesses() {
    if (this.state.selected.length < 1 || this.state.selected.length > 3) {
      return;
    }

    this.props.lockGuesses(this.state.selected);
  }

  enterCardAnswers() {
    if (this.state.selected.length !== 3) {
      return;
    }

    this.props.enterCardAnswers(this.state.selected);
  }

  // let cardMode = 'display';
  // if (this.allowGuesses()) cardMode = 'guess';
  // if (this.allowEnteringAnswers()) cardMode = 'enter-answers';

  choiceSelected(index) {
    if (this.props.mode === 'display') {
      return this.props.myGuesses.includes(index);
    } else {
      return this.state.selected.includes(index);
    }
  }

  render({ mode, myGuesses, lockGuesses, enterCardAnswers }) {
    return html`
      <div id="card">
        ${mode === 'guess' &&
        html`
          <div class="card-instructions">Select at most three answers for this question:</div>
        `}
        ${mode === 'enter-answers' &&
        html`
          <div class="card-instructions">Select the correct choices for this card:</div>
        `}

        <div
          class="card-choice ${this.choiceSelected(0) ? 'selected' : ''}"
          onClick=${() => this.toggleSelection(0)}
        >
          A
        </div>
        <div
          class="card-choice ${this.choiceSelected(1) ? 'selected' : ''}"
          onClick=${() => this.toggleSelection(1)}
        >
          B
        </div>
        <div
          class="card-choice ${this.choiceSelected(2) ? 'selected' : ''}"
          onClick=${() => this.toggleSelection(2)}
        >
          C
        </div>
        <div
          class="card-choice ${this.choiceSelected(3) ? 'selected' : ''}"
          onClick=${() => this.toggleSelection(3)}
        >
          D
        </div>
        <div
          class="card-choice ${this.choiceSelected(4) ? 'selected' : ''}"
          onClick=${() => this.toggleSelection(4)}
        >
          E
        </div>
        <div
          class="card-choice ${this.choiceSelected(5) ? 'selected' : ''}"
          onClick=${() => this.toggleSelection(5)}
        >
          F
        </div>

        ${mode === 'guess' &&
        html`
          <div class="card-submit" onClick=${this.lockGuesses.bind(this)}>Submit guesses</div>
        `}
        ${mode === 'enter-answers' &&
        html`
          <div class="card-submit" onClick=${this.enterCardAnswers.bind(this)}>
            Submit correct answers
          </div>
        `}
      </div>
    `;
  }
}
