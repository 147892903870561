import { html, Component } from 'htm/preact';
import { createRef } from 'preact';

export default class TextPrompt extends Component {
  constructor() {
    super();

    this.boundOnFocus = () => this.focusInput();
    this.boundOnClear = () => this.clearInput();

    this.formRef = createRef();
    this.focusRef = createRef();
  }

  focusInput() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  clearInput() {
    if (this.formRef.current) {
      this.formRef.current.reset();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.eventBus && !this.props.eventBus) {
      console.log('setting up listeners');
      nextProps.eventBus.on('focus', this.boundOnFocus);
      nextProps.eventBus.on('clear', this.boundOnClear);
    } else if (!nextProps.eventBus && this.props.eventBus) {
      console.log('detaching listeners');
      this.props.eventBus.off('focus', this.boundOnFocus);
      this.props.eventBus.off('clear', this.boundOnClear);
    }
  }

  componentDidMount() {
    if (this.props.eventBus) {
      console.log('setting up listeners on mount');
      this.props.eventBus.on('focus', this.boundOnFocus);
      this.props.eventBus.on('clear', this.boundOnClear);
    } else {
      console.log('NOT setting up listeners on mount');
    }
  }

  componentWillUnmount() {
    if (this.props.eventBus) {
      console.log('unmounting and removing listeners');
      this.props.eventBus.off('focus', this.boundOnFocus);
      this.props.eventBus.off('clear', this.boundOnClear);
    }
  }

  submitForm(form) {
    const formData = new FormData(form);
    const text = formData.get('text');
    if (text && text.trim() !== '') {
      this.props.submitForm(text);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.submitForm(e.target);
  }

  onKeyPress(e) {
    if (e.key !== 'Enter') {
      return;
    }
    e.preventDefault();
    this.submitForm(e.target.form);
  }

  render({ placeholder = '', label, extraClasses = '', submitLabel = 'Submit', maxLength = null }) {
    return html`
      <form
        ref=${this.formRef}
        class="exq__text-prompt ${extraClasses}"
        onsubmit=${e => this.onSubmit(e)}
      >
        <label for="exq--prompt">${label}</label>
        <textarea
          id="exq--prompt"
          ref=${this.focusRef}
          placeholder=${placeholder}
          class="exq__textarea"
          maxlength=${maxLength}
          onkeypress=${e => this.onKeyPress(e)}
          name="text"
        ></textarea>
        <button class="exq__button">${submitLabel}</button>
      </form>
    `;
  }
}
