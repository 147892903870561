import { render, html } from 'htm/preact';
import FriendlyWebSocket from './websocket';
import Socket from './interfaces/socket';
import App from './App';

let socket = new FriendlyWebSocket({});
Socket.setSocket(socket);

let initQueryParams = {};
try {
  const urlSearchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of urlSearchParams) {
    initQueryParams[key] = value;
  }
} catch (e) {
  console.warn('Could not extract initial query params');
}

const testServiceWorkers =
  initQueryParams.hasOwnProperty('testServiceWorkers') ||
  initQueryParams.hasOwnProperty('testNotifications');
if (testServiceWorkers && 'serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker
      .register('/sw.js?ts=' + new Date().getTime())
      .then(serviceWorkerRegistration => {
        // Registration was successful
        console.log(
          'ServiceWorker registration successful with scope: ',
          serviceWorkerRegistration.scope
        );
      })
      .catch(err => {
        console.warn('ServiceWorker registration failed: ', err);
      });
  });
} else if (testServiceWorkers) {
  console.warn('No browser serviceworker support');
}

render(
  html`
    <${App} initQueryParams=${initQueryParams} />
  `,
  document.body
);
