import { EventBus } from './utils/events';

const GlobalEventBus = new EventBus([
  // zoom events
  'setZoom',
  'zoomIn',
  'zoomOut',
]);

export default GlobalEventBus;
