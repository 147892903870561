export class GfxCanvas {
  constructor(ctx) {
    this.ctx = ctx;
  }
  transform(fn) {
    let ctx = this.ctx;
    ctx.save();
    fn(ctx);
    ctx.restore();
  }
  rect({ size, rotate = 0, scale = 1 }, fn) {
    let ctx = this.ctx;
    let s2 = size / 2;
    ctx.save();
    if (rotate) ctx.rotate(rotate);
    ctx.translate(-s2, -s2);
    if (scale) ctx.scale(scale, scale);
    fn(ctx);
    ctx.restore();
  }
}

export const RAD = Math.PI / 180;
