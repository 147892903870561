export function getLastUserId() {
  return localStorage.getItem('previousUserId');
}

export function setLastUserId(id) {
  localStorage.setItem('previousUserId', id);
}

export function getLastUserName() {
  return localStorage.getItem('lastUserName');
}

export function setLastUserName(name) {
  localStorage.setItem('lastUserName', name);
}
