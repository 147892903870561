import { html, Component } from 'htm/preact';
import { createRef } from 'preact';
import { connect } from 'unistore/full/preact.es.js';

import { outer, total, base } from 'gridy-avatars';

import LoginSignupModal from './LoginSignupModal';

const avatar = string => {
  let hash = 0,
    i,
    chr,
    len;
  if (string.length === 0) return hash;
  for (i = 0, len = string.length; i < len; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  const id = Math.floor((hash % total) + total)
    .toString(base)
    .padStart(6, '0');
  return outer(id);
};

class AccountMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { showLoginModal: false };
    this.accountAvatarRef = createRef();
  }

  openLoginModal(e) {
    this.setState({ showLoginModal: true });
    e.preventDefault();
    e.stopPropagation();
  }

  closeLoginModal() {
    this.setState({ showLoginModal: false });
  }

  componentDidUpdate() {
    if (this.props.account && this.accountAvatarRef.current) {
      this.accountAvatarRef.current.innerHTML = avatar(this.props.account.username);
    }
  }

  render({ account }, { showLoginModal }) {
    if (process.env.FEATURE_LOGIN !== '1') {
      return;
    }

    if (!account || !account.username) {
      const closeModal = this.closeLoginModal.bind(this);
      return html`
        <${LoginSignupModal}
          show=${showLoginModal}
          onClose=${closeModal}
          onLoginSuccess=${closeModal}
          onSignupSuccess=${closeModal}
        />
        <a class="account-login" onClick=${this.openLoginModal.bind(this)}>Login</a>
      `;
    }

    return html`
      <div class="account-menu" ref=${this.accountAvatarRef} />
    `;
  }
}

export default connect(['account'])(AccountMenu);
