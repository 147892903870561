import { html, Component } from 'htm/preact';
import Router from 'preact-router';

import { Provider, connect } from 'unistore/full/preact.es.js';

import store from './store';
import { addToast, clearToast, loadAccount } from './actions';

import About from './pages/About';
import LoginSignup from './pages/LoginSignup';
import Logout from './pages/Logout';
import MainMenu from './pages/MainMenu';
import Room from './pages/Room';

import ToastStack from './components/ToastStack';

import Audio from './interfaces/audio';
import Socket from './interfaces/socket';
import Notifications from './interfaces/notifications';

import { setLastUserId } from './localStorage';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      everConnected: false,
      connected: false,
    };

    Socket.on('message', msg => this.handleMessage(msg));
    Socket.on('open', _ => {
      clearToast('app:connection-lost');
      addToast({ type: 'success', message: 'Connected!' });
    });
    Socket.on('firstMessage', ({ type, data }) => {
      this.setState({ everConnected: true, connected: true });

      if (!this.state.everConnected && data.loggedIn) {
        loadAccount();
      }
    });
    Socket.on('close', _ => {
      this.setState({ connected: false });
      addToast({
        id: 'app:connection-lost',
        type: 'error',
        message: 'Conection Lost. Reconnecting...',
        persistent: true,
      });
    });
    window.addEventListener('resize', e => {
      this.setState({ height: window.innerHeight + 'px' });
    });
  }

  handleMessage(msg) {
    const { type, data } = msg;

    if (data && data.toast) {
      addToast(data.toast);
    }

    if (type === 'error' || type === 'game:error') {
      console.error('unexpected server error: ', msg);
    }

    if (type === 'user:id') {
      setLastUserId(data.id);
    }
  }

  setupAudio() {
    if (!this.startedAudio) {
      this.startedAudio = true;
      Audio.init();
    }
  }

  componentDidMount() {
    this.setState({ height: window.innerHeight + 'px' });
  }

  render({ appMode, initQueryParams }, { everConnected, height = '100%', toast }) {
    // show a simple message to minimize distraction while connecting to the socket
    // (maybe should replace with a animated loading indicator if we get some design
    // help or if Potch wants to contribute by working on something completely
    // unnecessary but probably pretty fun)
    if (!everConnected) {
      return html`
        Connecting...
      `;
    }

    const testNotification = {
      title: 'Test notification',
      body: 'Just checking to see that things are working',
      data: {},
      actions: [],
    };

    return html`
      <div id="app"
           class="app ${appMode}"
           onClick=${this.setupAudio.bind(this)}
           style=${appMode === 'game' && `height:${height};`}>

        <${ToastStack} />

        ${
          initQueryParams.hasOwnProperty('testNotifications') &&
          html`
            <a onClick=${e => Notifications.maybeTriggerNotification(testNotification)}>
              Test Notification
            </a>
          `
        }

        <${Router}>
          <${MainMenu}    path="/"
                          initQueryParams=${initQueryParams} />

          <${About}       path="/about" />
          <${LoginSignup} path="/login"
                          mode=${'login'} />
          <${LoginSignup} path="/signup"
                          mode=${'signup'} />
          <${Logout}      path="/logout" />

          <!-- This route doesn't actually do anything special -->
          <${MainMenu}    path="/exquisite"
                          gameType="Exquisite"
                          initQueryParams=${initQueryParams} />
          <!-- This route doesn't actually do anything special -->
          <${MainMenu}    path="/meepletown"
                          gameType="Meepletown"
                          initQueryParams=${initQueryParams} />

          <${Room}        path="/:roomCode"
                          height=${height}
                          initQueryParams=${initQueryParams}
          />
        </Router>
      </div>
    `;
  }
}

const connectedApp = connect(['appMode'])(App);

export default class AppWithStore extends Component {
  render(props) {
    return html`
      <${Provider} store=${store}>
        <${connectedApp} ...${props} />
      </Provider>
    `;
  }
}
