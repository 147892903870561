import { html, Component } from 'htm/preact';

const ordinalize = rank => {
  switch (rank % 10) {
    case 1:
      return html`
        ${rank}
        <sup>st</sup>
      `;
    case 2:
      return html`
        ${rank}
        <sup>nd</sup>
      `;
    case 3:
      return html`
        ${rank}
        <sup>rd</sup>
      `;
    default:
      return html`
        ${rank}
        <sup>th</sup>
      `;
  }
};

class Awards extends Component {
  // [
  //   {
  //     sectionTitle: 'Efficiency',
  //     metrics: [
  //       { metricTitle: 'Most Meeples Played', values: [{ playerId, value }, { playerId, value }, ...] },
  //       { metricTitle: 'Points Per Meeple',   values: [{ playerId, value }, { playerId, value }, ...] },
  //       { metricTitle: 'Most Meeples Scored', values: [{ playerId, value }, { playerId, value }, ...] },
  //     ],
  //   },
  // ]
  render({ awards, players, sortedPlayers }) {
    if (Object.keys(players).length === 1) {
      return html`
        <ul class="awards">
          ${awards.map(
            ({ sectionTitle, metrics }) => html`
              <li class="award-section">
                <ul class="award-metrics">
                  <li class="award-metric">
                    <div class="award-metric-title">${sectionTitle}</div>
                    <ol class="award-metric-values">
                      ${metrics.map(
                        ({ metricTitle, values }) => html`
                          <li>
                            <span class="value">${values[0].value}</span>
                            <span class="name">${metricTitle}</span>
                          </li>
                        `
                      )}
                    </ol>
                  </li>
                </ul>
              </li>
            `
          )}
        </ul>
      `;
    }

    return html`
      <ul class="awards">
        ${awards.map(({ sectionTitle, metrics }) => {
          return html`
            <li class="meep--award-section">
              <div class="meep--award-section__title">${sectionTitle}</div>
              ${metrics.map(({ metricTitle, values }) => {
                let maxValue = Math.max(...values.map(v => v.value));
                if (maxValue === 0) {
                  maxValue = 1;
                }
                const sortedValues = sortedPlayers.map(player =>
                  values.find(value => value.playerId === player.id)
                );

                return html`
                  <div class="meep--award-section__graph">
                    <div class="meep--award-section__graph__title">${metricTitle}</div>

                    ${sortedValues.map(({ playerId, value }) => {
                      const player = players[playerId];
                      const width = value === 0 ? '1px' : `${(value / maxValue) * 100}%`;

                      return html`
                        <div
                          class="meep--award-section__graph__row__name"
                          style="color: ${player.color}"
                        >
                          ${player.name}
                        </div>
                        <div class="meep--award-section__graph__row__bar-container">
                          <div
                            class="meep--award-section__graph__row__bar"
                            style="width: ${width}; background-color: ${player.color}"
                          >
                            <div class="meep--award-section__graph__row__bar__label">${value}</div>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                `;
              })}
            </li>
          `;
        })}
      </ul>
    `;
  }
}

export default class GameOver extends Component {
  constructor() {
    super();
    this.state = {
      dismissed: false,
    };
  }

  dismissEventListener() {
    this.setState({ opacity: 0 });
    setTimeout(() => {
      this.setState({ dismissed: true });
    }, 1000);
  }

  render({ endGameAwards, players }, { opacity = '1', dismissed }) {
    if (dismissed) {
      return html``;
    }

    const sortedPlayers = Object.values(players).sort((playerA, playerB) => {
      // Put FORFEITed players at the end of the list
      if (playerB.state === 'forfeit' && playerA.state !== 'forfeit') {
        return -1;
      }
      if (playerA.state === 'forfeit' && playerB.state !== 'forfeit') {
        return 1;
      }

      return playerB.score - playerA.score;
    });

    let previousPlayerRank = 0;
    let previousPlayerScore = null;
    let currentPlayerRank;
    let nextPlayerRank = 1;

    return html`
      <div id="meepletown-game-over" style="opacity:${opacity}" onclick=${e => e.stopPropagation()}>
        <ol class="score-table">
          ${sortedPlayers.map(player => {
            const classString = player.state === 'winner' ? 'winner' : '';

            if (player.score === previousPlayerScore) {
              currentPlayerRank = previousPlayerRank;
              nextPlayerRank += 1;
            } else {
              currentPlayerRank = nextPlayerRank;
              previousPlayerRank = currentPlayerRank;
              previousPlayerScore = player.score;
              nextPlayerRank += 1;
            }

            let rank = ordinalize(currentPlayerRank);
            if (player.state === 'forfeit') {
              rank = 'forfeit';
            }

            return html`
              <li class="${classString}">
                <span class="trophy">${player.state === 'winner' ? '🏆' : ''}</span>
                <span class="rank">${rank}</span>
                <span class="name" style="color:${player.color}">${player.name}</span>
                <span class="score">${player.score}</span>
              </li>
            `;
          })}
        </ol>

        <${Awards} awards=${endGameAwards} players=${players} sortedPlayers=${sortedPlayers} />
      </div>
    `;
  }
}
