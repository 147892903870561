import { html, Component } from 'htm/preact';
import { Link } from 'preact-router';

import AccountMenu from './AccountMenu';

export default class Header extends Component {
  render({ title, children, alreadyHome = false }) {
    return html`
      <header>
        <div class="game-room-banner">
          ${alreadyHome
            ? 'Game Room'
            : html`<${Link} class="game-room-banner__home" href="/">Game Room</Link>`}
        </div>

        <${AccountMenu} />
      </header>

      <h1 class="gr-title">${title}${children}</h1>
    `;
  }
}
