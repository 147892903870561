import { StaticEvented } from '../utils/events';

const DEBUG = false;
const log = DEBUG ? console.log.bind(window.console) : () => {};

const triggerNotification = Symbol('triggerNotification');

let currentPermissionResult;

export default class Notifications extends StaticEvented([
  'notificationsEnabledChange',
  'permissionResultChange',
]) {
  static supported() {
    if (!('Notification' in window) || !('serviceWorker' in navigator)) {
      return false;
    }

    return true;
  }

  static permissionResult() {
    let newPermissionResult;

    if (!this.supported()) {
      newPermissionResult = this.UNSUPPORTED;
    } else {
      newPermissionResult = window.Notification.permission;
    }

    if (newPermissionResult !== currentPermissionResult) {
      currentPermissionResult = newPermissionResult;
      this.emit('permissionResultChange', currentPermissionResult);
    }

    log('returning permissionResult', newPermissionResult);
    return newPermissionResult;
  }

  static async requestPermission() {
    return new Promise((resolve, reject) => {
      const currentPermission = this.permissionResult();

      switch (currentPermission) {
        case this.PERMISSION_GRANTED:
          return resolve(currentPermission);

        case this.PERMISSION_BLOCKED:
        case this.UNSUPPORTED:
          return reject(currentPermission);
      }

      window.Notification.requestPermission(newPermission => {
        if (newPermission !== currentPermission) {
          currentPermissionResult = newPermission;
          this.emit('permissionResultChange', currentPermissionResult);
        }

        if (newPermission === this.PERMISSION_GRANTED) {
          resolve(newPermission);
        } else {
          reject(newPermission);
        }
      });
    });
  }

  static maybeTriggerNotification({ title, body, icon, data, actions }) {
    if (!this.getNotificationsEnabled()) {
      log('maybeTriggerNotification early exit; notifications not enabled');
      return;
    }

    log('requestPermission called');
    return this.requestPermission()
      .then(() => {
        log('permission granted, about to call [triggerNotification]');
        return this[triggerNotification]({ title, body, icon, data, actions });
      })
      .catch(permission => console.warn('permission not granted, value is:', permission));
  }

  static [triggerNotification]({ title, body, icon, data, actions }) {
    log('in [triggerNotification]!');

    navigator.serviceWorker
      .getRegistration()
      .then(serviceWorkerRegistration => {
        if (!serviceWorkerRegistration) {
          log('no registered service worker!');
          return;
        }

        log('sending notification', { title, body, icon, data, actions });

        return serviceWorkerRegistration.showNotification(title, {
          body,
          icon,
          data,
          actions,
        });
      })
      .catch(err => console.error('service worker getRegistration error', err));
  }

  static getNotificationsEnabled() {
    const val = localStorage.getItem('notificationsEnabled');

    if (val) {
      return JSON.parse(val);
    }

    return true;
  }

  static setNotificationsEnabled(val) {
    localStorage.setItem('notificationsEnabled', JSON.stringify(val));
    this.emit('notificationsEnabledChange', val);
  }
}

currentPermissionResult = Notifications.permissionResult();

// Note: important to use values `granted`, `blocked`, `default` here as these
// have to match what's returned from window.Notification API
Notifications.PERMISSION_GRANTED = 'granted'; // permission request was granted
Notifications.PERMISSION_BLOCKED = 'blocked'; // permission request was denied and we cannot ask again
Notifications.PERMISSION_DEFAULT = 'default'; // not granted, but not blocked, can request permission
Notifications.UNSUPPORTED = 'unsupported'; // a non-standard status to simplify behavior logic

// Notifications.on('notificationsEnabledChange', (notificationsEnabled) => {
//   log('notificationsEnabledChange new value', notificationsEnabled);
// })

// Notifications.on('permissionResultChange', (permissionResult) => {
//   log('permissionResultChange new value', permissionResult);
// })
