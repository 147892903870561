import { html, Component } from 'htm/preact';
import { route } from 'preact-router';

import { setAppMode, addToast } from '../actions';

import LoginSignupForm from '../components/LoginSignupForm';
import Header from '../components/Header';

export default class LoginSignup extends Component {
  constructor(props) {
    super(props);

    setAppMode(props.mode || 'login');
  }

  onLoginSuccess() {
    addToast({ type: 'success', message: 'You are now logged in' });
    route('/');
  }

  onSignupSuccess() {
    addToast({ type: 'success', message: 'Thank you for registering' });
    route('/');
  }

  render({ mode }) {
    return html`
      <div class="login_signup__container">
        ${mode === 'login' &&
        html`
          <${Header} title="Login" />
        `};
        ${mode === 'signup' &&
        html`
          <${Header} title="Sign Up" />
        `};

        <${LoginSignupForm}
          hideTitle=${true}
          multiMode=${false}
          mode=${mode}
          onLoginSuccess=${this.onLoginSuccess.bind(this)}
          onSignupSuccess=${this.onSignupSuccess.bind(this)}
        />
      </div>
    `;
  }
}
