import { html, Component } from 'htm/preact';

import Modal from './Modal';
import LoginSignupForm from './LoginSignupForm';

class LoginSignupModal extends Component {
  render({ show, onClose, onLoginSuccess, onSignupSuccess, onPasswordResetSuccess }) {
    return html`
      <${Modal}
          show=${show}
          key="login-signup-modal"
          variation=${Modal.VARIATION_CLOSE_WITH_X}
          onClose=${() => onClose()}
          extraClassString="login-signup-modal"
      >
        <${LoginSignupForm}
            onLoginSuccess=${onLoginSuccess}
            onSignupSuccess=${onSignupSuccess}
            onPasswordResetSuccess=${onPasswordResetSuccess} />
      </Modal>
    `;
  }
}

export default LoginSignupModal;
