import { html, Component } from 'htm/preact';

export default class Settings extends Component {
  static registeredSettings() {
    return [];
  }

  render({ settings, changeGameSettings }) {
    return;

    /* Keeping this here so we can reboot settings easily if needed */
    return html`
      <div>
        <label>
          <input
            class="gr-input"
            type="checkbox"
            checked=${!!settings.useChessTimer}
            onChange=${e => changeGameSettings({ useChessTimer: !settings.useChessTimer })}
          />
          Use Chess Timer
        </label>

        ${process.env.FEATURE_LEGACY === '1' &&
        html`
          <label>
            <input
              class="gr-input"
              type="checkbox"
              checked=${!!settings.legacy}
              onChange=${e => changeGameSettings({ legacy: !settings.legacy })}
            />
            Legacy
          </label>
        `}
        ${process.env.FEATURE_STASH_TILES === '1' &&
        html`
          <label>
            <input
              class="gr-input"
              type="checkbox"
              checked=${!!settings.stash}
              onChange=${e => changeGameSettings({ stash: !settings.stash })}
            />
            Tile Stash
          </label>
          <label>
            Stash Size
            <input
              class="gr-input gr-input--settings"
              type="text"
              defaultValue=${settings.stashSize}
              onChange=${e => changeGameSettings({ stashSize: parseInt(e.target.value) })}
            />
          </label>
        `}
      </div>
    `;
  }
}
