import { html } from 'htm/preact';
import { createRef } from 'preact';
import { createPortal } from 'preact/compat';

import { EventBus } from '../../utils/events';
import { formatTime } from '../../utils';
import Game from '../game';
import TextPrompt from './TextPrompt.js';
import Toolbar from './Toolbar';

import './exquisite.css';

const emojiForStory = (i, story) => {
  switch (i) {
    case 0:
      return '😳';
    case 1:
      return '😂';
    case 2:
      return '😬';
    case 3:
      return '🤯';
    case 4:
    default:
      return story && story.emoji ? story.emoji : '😝';
  }
};

class Exquisite extends Game {
  constructor(props) {
    super(props);

    const { user } = props;

    this.formRef = createRef();
    this.focusRef = createRef();
    this.hostRef = createRef();
    this.countdownRef = createRef();
    this.emojiReactionRef = createRef();

    this.state = {
      ...this.state,
      userId: user.id,
      hideGameOver: false,
      phase: null,
      countdownRunning: false,
      allowMoreInput: true,
    };

    this.eventBus = new EventBus(['focus', 'clear']);

    window.addEmojiReaction = i => this.addEmojiReaction(i);
  }

  myPlayer() {
    const { players = {}, userId = null } = this.state;
    return players[userId] || {};
  }

  playerName(playerId) {
    const { players = {} } = this.state;
    return (players[playerId] || {}).name || 'Anonymous';
  }

  gameOver(state = this.state) {
    return state.gameState && state.gameState.phase === 'gameover';
  }

  componentWillReceiveGameState(nextState) {
    const { gameState: nextGameStateNullable } = nextState;
    const { gameState: currentGameStateNullable } = this.state;

    const currentGameState = currentGameStateNullable || {};
    const nextGameState = nextGameStateNullable || {};

    if (
      currentGameState.phase &&
      nextGameState.phase &&
      currentGameState.phase !== nextGameState.phase
    ) {
      this.endCountdown();
      this.setState({ allowMoreInput: true });
    }

    if (nextGameState.countdownRunning) {
      this.startOrUpdateCountdown(nextGameState.countdownRemaining);
    }
    if (currentGameState.countdownRunning && !nextGameState.countdownRunning) {
      this.endCountdown();
    }

    super.componentWillReceiveGameState(nextGameState);
  }

  handleMessage({ type, data }) {
    super.handleMessage({ type, data });

    if (type === 'game:data-accepted') {
      this.onDataAccepted({ allowMore: data.allowMore });
      return;
    }

    if (type === 'game:presentation-emoji') {
      this.addEmojiReaction(data.emojiIndex);
      return;
    }

    if (type !== 'game:state' && type !== 'game:state-delta') {
      console.log('got new non-game-state message', type, data);
    }
  }

  startOrUpdateCountdown(newDuration = null) {
    if (!this.countdownEnd && newDuration) {
      this.countdownEnd = Date.now() + newDuration;
      this.countdownInterval = setInterval(() => this.startOrUpdateCountdown(), 50);
      this.minimumCountdownRemaining = Infinity;
    }

    if (newDuration === null) {
      newDuration = this.countdownEnd - Date.now();
    }

    if (newDuration < 0) {
      this.endCountdown();
      return;
    }

    const newCountdown = Math.min(this.minimumCountdownRemaining, Math.floor(newDuration / 1000));
    if (!this.state.countdownRunning) {
      this.setState({ countdownRunning: true });
    } else if (this.countdownRef && this.countdownRef.current) {
      this.countdownRef.current.innerText = formatTime(newCountdown * 1000);
    }
    this.minimumCountdownRemaining = newCountdown;
  }

  endCountdown() {
    this.countdownEnd = null;
    this.minimumCountdownRemaining = 0;
    clearInterval(this.countdownInterval);
    this.setState({ countdownRunning: false });
    if (this.countdownRef && this.countdownRef.current) {
      this.countdownRef.current.innerText = '0:00';
    }
  }

  submitTitle(title) {
    this.sendGameAction({ type: 'game:suggest-title', data: { title } });
  }

  selectTitle(e, selectedTitleId) {
    e.preventDefault();
    this.sendGameAction({ type: 'game:select-title', data: { selectedTitleId } });
  }

  submitSentence(sentence) {
    this.sendGameAction({ type: 'game:sentence', data: { sentence } });
  }

  advancePresentation() {
    this.sendGameAction({ type: 'game:advance-story' });
  }

  submitBlurb(blurb) {
    this.sendGameAction({ type: 'game:blurb', data: { blurb } });
  }

  givePresentationProps(emojiIndex) {
    this.sendGameAction({ type: 'game:presentation-emoji', data: { emojiIndex } });
  }

  addEmojiReaction(emojiIndex) {
    if (this.emojiReactionRef.current) {
      const el = document.createElement('div');
      el.innerText = emojiForStory(emojiIndex, (this.state.gameState || {}).storyBeingPresented);
      el.classList.add('exq__emoji-reaction');
      el.style.left = Math.random() * 100 + '%';
      el.style.top = 100 + Math.random() * 5 + '%';
      el.style.animationDuration = 2 + Math.random() + 's';
      el.style.fontSize = 2 + Math.random() + 'em';
      this.emojiReactionRef.current.append(el);
      el.addEventListener('animationend', () => el.parentElement.removeChild(el));
    }
  }

  onDataAccepted({ allowMore = true }) {
    if (!allowMore) {
      this.setState({ allowMoreInput: false });
    }

    this.eventBus.emit('clear');
  }

  componentDidUpdate() {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  }

  componentDidCatch(error) {
    console.error('Got error', error);
  }

  render(
    { assets, gameOverResultsRef },
    { players, gameState, countdownRunning, userId, allowMoreInput = true }
  ) {
    gameState = gameState || {};
    const {
      phase: gamePhase,
      host = {},
      sentenceNumber,
      numSentences,
      authorPlayerId, // This is the author who's presenting or the author whose blurbs are being presented
      storyBeingPresented, // This is the story being presented or whose blurbs are being presented
      blurbIndex, // This is the blurb
      stories, // This should only be populated when the game is over
    } = gameState;
    const {
      proposedTitles = [],
      assignedTitles = [],
      selectedTitle,
      previousSentence,
    } = this.myPlayer();
    const myStory = authorPlayerId === userId;

    const hostOnTop = gamePhase === 'present' && countdownRunning;

    if (this.gameOver()) {
      return createPortal(
        html`
          <div class="exq__game-over">
            <${Toolbar} pageName="Print Preview" icons=${['🖨']} players=${players} />
            ${stories &&
            Object.entries(stories).map(([playerId, story]) => {
              const seenPlayers = new Set();
              const titleAuthor = story.title.playerId
                ? players[story.title.playerId].name
                : 'the computer';
              return html`
                <div class="exq__game-over__story">
                  <div class="exq__game-over__story__title">${story.title.text}</div>
                  <div class="exq__game-over__story__byline">
                    by ${players[playerId].name} (title: ${titleAuthor})
                  </div>
                  <div class="exq__game-over__story__body">
                    ${story.sentences.map(sentence => {
                      const showPlayerCursor = !seenPlayers.has(sentence.playerId);
                      const sentenceAuthor = players[sentence.playerId];
                      const sentenceColor = sentenceAuthor ? sentenceAuthor.color : '#000000';
                      const sentenceAuthorName = sentenceAuthor ? sentenceAuthor.name : 'Computer';
                      seenPlayers.add(sentence.playerId);
                      return html`
                        ${showPlayerCursor &&
                        html`
                          <span
                            class="exq__game-over__story__cursor"
                            style="--player-color:${sentenceColor}"
                          >
                            <div class="exq__game-over__story__cursor__name">
                              ${sentenceAuthorName}
                            </div>
                          </span>
                        `}
                        <span class="exq__story--sentence" style="color: ${sentenceColor}">
                          ${sentence.text}
                        </span>
                      `;
                    })}
                  </div>
                </div>
              `;
            })}
          </div>
        `,
        gameOverResultsRef.current
      );
    }

    return html`
      <${Toolbar} pageName="Untitled" icons=${['💾', '🔮', '🚽', '🖨']} players=${players} />
      <div class="exq__content">
        ${gamePhase === 'titles' &&
        html`
          ${allowMoreInput &&
          html`
            <${TextPrompt}
              eventBus=${this.eventBus}
              label="Title your story"
              submitForm=${title => this.submitTitle(title)}
            />
          `}
          ${proposedTitles.length > 0 &&
          html`
            <h3>Your Proposed Titles:</h3>
            <ul class="exq__proposed-titles">
              ${proposedTitles.map(
                proposedTitle => html`
                  <li>${proposedTitle}</li>
                `
              )}
            </ul>
          `}
        `}
        ${gamePhase === 'select' &&
        selectedTitle &&
        html`
          <h2>your story starts with the title:</h2>
          <div class="exq__selected">${selectedTitle.text}</div>
        `}
        ${gamePhase === 'select' &&
        !selectedTitle &&
        assignedTitles.length > 0 &&
        html`
          <h2>select a title for your story:</h2>
          <div class="exq__select">
            ${assignedTitles.filter(Boolean).map(
              ({ text }, titleId) => html`
                <div class="exq__select__option" onClick=${e => this.selectTitle(e, titleId)}>
                  ${text}
                </div>
              `
            )}
          </div>
        `}
        ${gamePhase === 'sentence' &&
        previousSentence &&
        html`
          ${sentenceNumber === 0 &&
          html`
            <h2>${previousSentence}</h2>
          `}
          ${sentenceNumber !== 0 &&
          html`
            <h3>${previousSentence}</h3>
          `}
          ${allowMoreInput &&
          html`
            <${TextPrompt}
              eventBus=${this.eventBus}
              label=${sentenceNumber === 0
                ? 'Write the first sentence'
                : 'What sentence comes next?'}
              submitForm=${sentence => this.submitSentence(sentence)}
            />
          `}
          ${!allowMoreInput &&
          html`
            Waiting on other players...
          `}

          <div class="exq__sentence__progress">
            Sentence ${sentenceNumber + 1} of ${numSentences}
          </div>
        `}
        ${gamePhase === 'present' &&
        storyBeingPresented &&
        storyBeingPresented.title &&
        storyBeingPresented.sentences &&
        html`
          <div class="exq__story">
            <h2>${storyBeingPresented.title.text}</h2>
            ${sentenceNumber >= 0 &&
            storyBeingPresented.sentences.slice(0, sentenceNumber + 1).map(
              sentence => html`
                <span class="exq__story--sentence">${sentence.text}</span>
              `
            )}
            ${myStory &&
            html`
              <div class="exq__button-bottom-container">
                <button class="exq__button" onclick=${e => this.advancePresentation()}>
                  ${sentenceNumber < numSentences - 1 ? 'Next Sentence' : 'The End'}
                </button>
              </div>
            `}
            ${!myStory &&
            html`
              <div class="exq__button-bottom-container">
                ${[...Array(5).keys()].map(
                  i => html`
                    <button
                      class="exq__button exq__button--emoji"
                      onclick=${e => this.givePresentationProps(i)}
                    >
                      ${emojiForStory(i, storyBeingPresented)}
                    </button>
                  `
                )}
              </div>
            `}
          </div>
        `}
        ${gamePhase === 'blurb' &&
        !myStory &&
        html`
          ${allowMoreInput &&
          html`
            <${TextPrompt}
              eventBus=${this.eventBus}
              label="Write a review for “${storyBeingPresented.title.text}”"
              submitForm=${blurb => this.submitBlurb(blurb)}
            />
          `}
        `}
        ${gamePhase === 'blurb' &&
        myStory &&
        html`
          <div>Take a seat while the other players provide a blurb for your story</div>
        `}
        ${gamePhase === 'reviews' &&
        html`
          <div class="exq__blurb">
            <h2>${storyBeingPresented.title.text}</h2>
            <div>
              <span class="exq__blurb--text">${storyBeingPresented.blurbs[blurbIndex].text}</span>
            </div>
            <div class="exq__blurb--author">
              — ${this.playerName(storyBeingPresented.blurbs[blurbIndex].playerId)}
            </div>
          </div>
        `}
      </div>
      <div class="exq__host ${hostOnTop ? 'exq__host--top' : ''}" ref=${this.hostRef}>
        <div class="exq__header"></div>
        ${countdownRunning &&
        html`
          <div class="exq__host__content exq__host__countdown" key="countdown">
            <span ref=${this.countdownRef} />
          </div>
        `}
        ${!countdownRunning &&
        html`
          <div class="exq__host__content exq__host__snippy" key="snippy">
            <img src="/games/exquisite/assets/snippy.png" />
          </div>
        `}
        ${host.message &&
        html`
          <div class="exq__host__speech">${host.message}</div>
          <svg class="exq__host__speech__callout" width="40" height="32">
            <path d="M32,0 L39,31 1,0" />
          </svg>
        `}
      </div>
      <div class="exq__emoji-reactions" ref=${this.emojiReactionRef}></div>
    `;
  }
}

export default Exquisite;
