import { StaticEvented } from '../utils/events';

const logInboundSocketMessages = process.env.LOG_INCOMING_SOCKET_MESSAGES_CLIENT === '1';
const logOutboundSocketMessages = process.env.LOG_OUTGOING_SOCKET_MESSAGES_CLIENT === '1';
let rpcSequenceId = 1;
const pendingRpcs = new Map();

const extractPendingRpc = sequenceId => {
  const pendingRpc = pendingRpcs.get(sequenceId);
  pendingRpcs.delete(sequenceId);
  return pendingRpc;
};

export default class Socket extends StaticEvented(['message', 'open', 'close', 'firstMessage']) {
  static setSocket(newSocket) {
    if (socket) {
      throw new Error('already connected socket');
    }

    socket = newSocket;

    socket.on('message', rawMsg => {
      let msg;
      try {
        msg = JSON.parse(rawMsg);
      } catch (e) {
        console.warn(e);
        return;
      }

      if (!isConnected) {
        isConnected = true;
        this.emit('firstMessage', msg);
      }

      if (logInboundSocketMessages) {
        console.log('>> Socket message received: ', msg);
      }

      this.emit('message', msg);

      if (msg.type === 'rpc:response') {
        this.handleRpcResponse(msg);
      }
    });

    socket.on('open', e => this.emit('open', e));
    socket.on('close', e => {
      isConnected = false;
      this.emit('close', e);
    });
  }

  static send({ type, data, sequenceId }) {
    if (!socket) {
      console.error('Did not attach socket before sending');
      return;
    }

    try {
      socket.send({
        type,
        data,
        ...(sequenceId ? { sequenceId } : {}),
      });

      if (logOutboundSocketMessages) {
        console.log('<< Socket message sent: ', { type, data });
      }
    } catch (e) {
      console.warn('socket send error', e);
    }
  }

  static rpc({ type, data, timeout }) {
    return new Promise((resolve, reject) => {
      const sequenceId = rpcSequenceId++;

      let timeoutId;

      if (timeout > 0) {
        timeoutId = setTimeout(() => this.timeoutRpcResponse({ sequenceId }), timeout);
      }

      pendingRpcs.set(sequenceId, { resolve, reject, timeoutId });

      this.send({ type: 'rpc:request', sequenceId, data: { type, data } });
    });
  }

  static timeoutRpcResponse({ sequenceId }) {
    const pendingRpc = extractPendingRpc(sequenceId);
    if (!pendingRpc) {
      return;
    }

    pendingRpc.reject({ type: 'timeout' });
  }

  static handleRpcResponse({ sequenceId, data: responseMessage, error }) {
    console.log('handleRpcResponse', { sequenceId, responseMessage, error });
    const pendingRpc = extractPendingRpc(sequenceId);
    if (!pendingRpc) {
      return;
    }

    if (pendingRpc.timeoutId) {
      clearTimeout(pendingRpc.timeoutId);
    }

    if (error) {
      console.warn('rpc error', error);
      pendingRpc.reject(error);
      return;
    }

    pendingRpc.resolve(responseMessage);
  }
}

let socket;
let isConnected = false;
