import { html, Component } from 'htm/preact';

export default class Die extends Component {
  // { name:  '1' , distance: 1, amped: false, find_lies: false },
  // { name:  '2' , distance: 2, amped: false, find_lies: false },
  // { name:  '3' , distance: 3, amped: false, find_lies: false },
  // { name:  '4' , distance: 4, amped: false, find_lies: false },
  // { name:  '1+', distance: 1, amped: true,  find_lies: false },
  // { name: '¡2!', distance: 2, amped: false, find_lies: true  },

  render({ die: { name, distance, amped, find_lies: findLies } }) {
    return html`
      <div id="die">
        <div class="die-face">${name}</div>
        <ul class="die-description">
          <li>Advance ${distance} when all guesses are correct</li>
          ${amped &&
          html`
            <li>Amped! Score double Victory Points from Doubling Up or Going All In</li>
          `}
          ${findLies &&
          html`
            <li>Half Lie! Advance by guessing the incorrect answers</li>
          `}
        </ul>
      </div>
    `;
  }
}
