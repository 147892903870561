import store from '../store';

import Socket from '../interfaces/socket';

export const setAccount = store.action((state, account) => ({ account }));

export const loadAccount = store.action(state =>
  Socket.rpc({ type: 'account:whoami' })
    .then(({ data }) => {
      store.setState({
        account: data,
      });
    })
    .catch(err => console.error('account:whoami error', err))
);
