import PromiseQueue from './promiseQueue';

const ASSET_CHUNK_COUNT = 5;

const DEBUG = false;
const log = DEBUG ? console.log.bind(window.console) : () => {};

function patch(obj, path, value) {
  let cursor = obj;
  for (let i = 0; i < path.length - 1; i++) {
    let key = path[i];
    if (key in cursor) {
      cursor = cursor[key];
    } else {
      throw new Error(`invalid path to patch: ${path.join('.')}`);
    }
  }
  cursor[path[path.length - 1]] = value;
}

export default class AssetLoader {
  static loadManifestAndTree(manifestFilePath, progressCallback) {
    return fetch(manifestFilePath)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then(async assetJson => {
        log('loading asset tree', assetJson);
        let loader = new AssetLoader();
        let assets = await loader.loadTree(assetJson, progressCallback);

        return Promise.resolve({ assets, assetsReady: true });
      })
      .catch(error => {
        if (error === 404) {
          return Promise.resolve({ assets: null, assetsReady: false });
        }

        return Promise.reject(error);
      });
  }

  async loadTree(treeObj, progressCallback) {
    const loaderTasks = [];

    function walk(obj, out, path = []) {
      for (let [key, value] of Object.entries(obj)) {
        if (obj instanceof Array) {
          key = parseInt(key);
        }
        if (typeof value === 'string') {
          loaderTasks.push([[...path, key], value]);
        } else if (value instanceof Array) {
          out[key] = walk(value, [], [...path, key]);
        } else if (typeof value === 'object') {
          out[key] = walk(value, {}, [...path, key]);
        }
      }
      return out;
    }

    const result = walk(treeObj, {});
    let progress = 0;

    const promiseQueue = new PromiseQueue({ concurrency: 5 });

    for (let i = 0; i < loaderTasks.length; i++) {
      let [path, url] = loaderTasks[i];
      promiseQueue.enqueue(
        i,
        () =>
          new Promise((resolve, reject) => {
            log(`Running asset load ${i}`);
            return fetch(url)
              .then(response => {
                log(`Fetch asset ${i} succeeded`);
                progress++;
                if (progressCallback) {
                  progressCallback(progress, loaderTasks.length);
                }
                let type = response.headers.get('content-type');
                patch(result, path, response);
                if (type.startsWith('image/')) {
                  let img = new Image();
                  img.onload = () => {
                    resolve();
                  };
                  img.src = response.url;
                  response.image = img;
                } else {
                  resolve();
                }
              })
              .catch(err => reject(err));
          })
      );
    }

    await promiseQueue.finished();

    return result;
  }
}
