import { html, Component } from 'htm/preact';

import { addToast } from '../../actions';

import Audio from '../../interfaces/audio';
import Notifications from '../../interfaces/notifications';

import Modal from '../../components/Modal';
import LoginSignupModal from '../../components/LoginSignupModal';

class Menu extends Component {
  constructor() {
    super();

    this.state = {
      menuVisible: false,
      loginSignupVisible: false,
      notificationsEnabled: Notifications.getNotificationsEnabled(),
      permissionResult: Notifications.permissionResult(),
      showForfeitModal: false,
      volume: Audio.getVolume(),
      muted: Audio.getMuted(),
    };

    Notifications.on('notificationsEnabledChange', notificationsEnabled =>
      this.setState({ notificationsEnabled })
    );
    Notifications.on('permissionResultChange', permissionResult =>
      this.setState({ permissionResult })
    );

    Audio.on('mutedChange', muted => this.setState({ muted }));
    Audio.on('volumeChange', volume => this.setState({ volume }));

    this.dismissClickListener = e => {
      e.stopPropagation();

      this.removeDismissClickListener();
      this.toggleMenu(false);
    };
  }

  toggleMenu(visibility = !this.state.menuVisible) {
    this.setState({ menuVisible: visibility });

    if (visibility) {
      this.attachDismissClickListener();
    } else {
      this.removeDismissClickListener();
    }
  }

  clickToggleMenu(e) {
    e.stopPropagation();
    this.toggleMenu();
  }

  attachDismissClickListener() {
    document.body.addEventListener('click', this.dismissClickListener);
  }

  removeDismissClickListener() {
    document.body.removeEventListener('click', this.dismissClickListener);
  }

  showForfeitModal(e) {
    if (e && 'stopPropagation' in e) {
      e.stopPropagation();
    }
    this.setState({ showForfeitModal: true });
  }

  hideForfeitModal() {
    this.setState({ showForfeitModal: false });
  }

  forfeit() {
    this.hideForfeitModal();
    this.props.forfeit();
  }

  notificationSubscribe(e) {
    e.stopPropagation();

    Notifications.setNotificationsEnabled(!this.state.notificationsEnabled);

    // Note that this doesn't actually send a notification if notifications are not enabled
    Notifications.maybeTriggerNotification({
      title: 'Game Room',
      body: 'Notifications are enabled',
    });
  }

  toggleMuted(e) {
    e.stopPropagation();
    // Note that changing the muted state will trigger an `Audio.on('mutedChange', cb)` event
    // which sets state in component due to listener configured in constructor
    Audio.setMuted(!this.state.muted);
  }

  adjustVolume(e) {
    e.stopPropagation();
    // Note that changing the volume will trigger an `Audio.on('volumeChange', cb)` event
    // which sets state in component due to listener configured in constructor
    Audio.setVolume(e.target.value);
  }

  showLoginSignupModal(e) {
    e.stopPropagation();
    this.toggleMenu(false);
    this.setState({ loginSignupVisible: true });
  }

  hideLoginSignupModal() {
    this.setState({ loginSignupVisible: false });
  }

  onLoginSuccess() {
    addToast({ type: 'success', message: 'You are now logged in' });
    this.setState({ loginSignupVisible: false });
    this.toggleMenu(false);
  }

  onSignupSuccess() {
    addToast({ type: 'success', message: 'You are now registered' });
    this.setState({ loginSignupVisible: false });
    this.toggleMenu(false);
  }

  render(
    {},
    {
      menuVisible,
      loginSignupVisible,
      notificationsEnabled,
      permissionResult,
      showForfeitModal,
      muted,
      volume,
    }
  ) {
    /*
      Menu Item ideas:

      - Toggle UI Hints
      - Enable/Disable Sound
      - Show / Hide chat
      - Debug on/off
    */

    let notificationText = 'Enable notifications';
    if (permissionResult === Notifications.PERMISSION_GRANTED && notificationsEnabled) {
      notificationText = 'Disable notifications';
    }

    let currentMutedText = muted ? '🔇' : '🔈';

    return html`
      <button class="control-button toggle-menu" onClick=${this.clickToggleMenu.bind(this)}>
        <svg style="width:.9em;height:.9em;" width="16" height="16" viewBox="0 0 16 16">
          <rect x="0" y="0" width="16" height="3"/>
          <rect x="0" y="6.5" width="16" height="3"/>
          <rect x="0" y="13" width="16" height="3"/>
        </svg>
      </button>

      <nav class="menu" role="navigation">
        ${
          menuVisible &&
          html`
            <ul>
              <li class="menu-item">
                <a class="menu-item--stretch" onClick=${this.showForfeitModal.bind(this)}>
                  Forfeit
                </a>
              </li>

              ${(permissionResult === Notifications.PERMISSION_DEFAULT ||
                permissionResult === Notifications.PERMISSION_GRANTED) &&
              html`
                <li class="menu-item">
                  <a class="menu-item--stretch" onClick=${this.notificationSubscribe.bind(this)}>
                    ${notificationText}
                  </a>
                </li>
              `}
              <li class="menu-item">
                <div>Sounds</div>
                <div onClick=${e => this.toggleMuted(e)}>${currentMutedText}</div>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  onClick=${e => e.stopPropagation()}
                  class="menu-item--stretch"
                  value=${volume}
                  onInput=${e => this.adjustVolume(e)}
                  onChange=${e => this.adjustVolume(e)}
                />
              </li>
              ${process.env.FEATURE_LOGIN === '1' &&
              html`
                <li class="menu-item">
                  <a class="menu-item--stretch" onClick=${this.showLoginSignupModal.bind(this)}>
                    Login
                  </a>
                </li>
              `}
            </ul>
          `
        }
      </nav>

      <${Modal} key="forfeit"
                show=${showForfeitModal}
                confirmText="Forfeit"
                onConfirm=${this.forfeit.bind(this)}
                closeText="Cancel"
                onClose=${this.hideForfeitModal.bind(this)}>
        Are you sure you want to forefeit?
      </Modal>

      <${LoginSignupModal} show=${loginSignupVisible}
                           onClose=${this.hideLoginSignupModal.bind(this)}
                           onLoginSuccess=${this.onLoginSuccess.bind(this)}
                           onSignupSuccess=${this.onSignupSuccess.bind(this)}
                           onResetPasswordSuccess=${() => {}}
      />
    `;
  }
}

export default Menu;
