import { html, Component } from 'htm/preact';
import { meeple } from './meeple';

import { CENTER, NORTH, EAST, SOUTH, WEST } from './constants';

export default class Meepler extends Component {
  render({ placeMeeple, playerColor, allowedMeepleLocations }) {
    let canMeepleTop = allowedMeepleLocations.includes(NORTH);
    let canMeepleRight = allowedMeepleLocations.includes(EAST);
    let canMeepleBottom = allowedMeepleLocations.includes(SOUTH);
    let canMeepleLeft = allowedMeepleLocations.includes(WEST);
    let canMeepleMiddle = allowedMeepleLocations.includes(CENTER);

    return html`
      <div id="meepler">
        <div class="tile-overlay">
          <div>
            <button
              disabled=${!canMeepleTop}
              class="meeple-north"
              onClick=${() => placeMeeple(NORTH)}
            >
              ${meeple(playerColor)}
            </button>
            <button
              disabled=${!canMeepleLeft}
              class="meeple-west"
              onClick=${() => placeMeeple(WEST)}
            >
              ${meeple(playerColor)}
            </button>
            <button
              disabled=${!canMeepleMiddle}
              class="meeple-center"
              onClick=${() => placeMeeple(CENTER)}
            >
              ${meeple(playerColor)}
            </button>
            <button
              disabled=${!canMeepleRight}
              class="meeple-east"
              onClick=${() => placeMeeple(EAST)}
            >
              ${meeple(playerColor)}
            </button>
            <button
              disabled=${!canMeepleBottom}
              class="meeple-south"
              onClick=${() => placeMeeple(SOUTH)}
            >
              ${meeple(playerColor)}
            </button>
          </div>
        </div>
        <button onClick=${() => placeMeeple('')} class="meeple-none">No Meeple</button>
      </div>
    `;
  }
}
